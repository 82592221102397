.bigger-video-bx {
  width: 100%;
  height: 90vh;
}
.header-2-wc {
  position: absolute;
  z-index: 1000;
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100%;
}
.header-2-wc img {
  background-color: black;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 5px;
}
.play-big-text {
  height: 100%;

  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  margin-left: 50px;
}
.button-test button {
  margin: 10px;
}
.bigger-video-bx > div {
  width: 100% !important;
}
.bigger-video-bx > div > video {
  width: 100% !important;
  object-fit: cover;
  height: 90vh !important;
  box-shadow: rgba(0, 0, 0, 1) 0px -100px 66px -48px inset !important;
}

.player-card-video > div {
  width: 100% !important;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px -100px 66px -48px inset; */
  height: 150px !important;
}
.card-img-wc {
  width: 100% !important;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px -100px 66px -48px inset; */
  height: 150px !important;
  object-fit: cover;
  cursor: pointer;
  padding: 3px;
}
.player-card-video > div > video {
  width: 100% !important;
  object-fit: cover;
  height: 100% !important;
  border-radius: 2px;
}
.created-slider-bx {
  position: relative;
  z-index: 20;
  top: -50px;
  margin-bottom: 50px;
  padding: 0px 35px;
}
.created-slider-bx h3 {
  color: rgb(212, 212, 212);
  font-size: 18px;
}
.card-video-main-wc {
  padding: 3px;
  overflow: hidden;
}
.card-video-main-wc {
  position: absolute;
  /* background-color: black; */
  border-radius: 2px;
  animation-name: card-view;
  animation-duration: 1s;
  transform: scale(1.4);
}
@keyframes card-view {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.4);
  }
}
.created-slider-bx {
  width: 100%;
  /* overflow-x: hidden; */
}
.created-slider-bx .slick-list {
  overflow: visible !important;
}
.show-hover {
  display: none;
}
.card-video-main-wc:hover .hide-hover {
  display: none;
}
.card-video-main-wc:hover .show-hover {
  display: block;
}
.watch-create-container {
  width: 100vw;
  overflow-x: hidden;
}
.created-card-bx-bottom {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 5px;
  background-color: black;
}
.slick-prev,
.slick-next {
  z-index: 150;
}
.created-card-bx-bottom i {
  font-size: 8px;
}
.tabs-new-wc .button-tab {
  background: linear-gradient(to right, rgb(152, 46, 87), rgb(1, 99, 148));
  position: relative;
  z-index: 40;
}
.tabs-new-wc .button-tab:hover {
  transform: scale(1.05);
  transition: 0.4s ease all;
  cursor: pointer;
}
.tabs-new-wc {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.top-20-modal {
  top: 20px;
}
.header-2-wc {
  display: flex;
  justify-content: space-between;
}

.create-box-om {
  width: 500px;

  /* background-color: antiquewhite; */
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 10px;
  z-index: 100;
  top: 50px;
  animation-name: card-create;
  animation-duration: 0.5s;
  border-radius: 10px;
}
@keyframes card-create {
  from {
    right: -200px;
  }
  to {
    right: 10px;
  }
}
.create-box-om .create-tab{
  margin-top: 0px;
  background-color: transparent;
}
/* .create-main-con-2{
} */
