.pen-title {
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}

.pen-description {
  color: #ffffff;
  margin-bottom: 3rem;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}
.pen-description a {
  color: #ffffff;
  text-decoration: underline;
}

.pricing-section a {
  text-decoration: none;
}
.pricing-section a:hover {
  text-decoration: underline;
}

.pricing-section img {
  height: auto;
  max-width: 100%;
}

.wrap {
  max-width: 85.375rem;
  margin: 0 auto;
}

.image-as-background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.screen-reader-text {
  height: auto;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}
.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
  height: auto;
  width: auto;
  background-color: #000000;
  border-radius: 2px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #ffffff;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  left: 0.3125rem;
  line-height: normal;
  padding: 0.9375rem 1.4375rem 0.875rem;
  text-decoration: none;
  top: 0.3125rem;
  z-index: 5;
}

@media screen and (min-width: 64rem) {
  .wrap {
    padding: 1.25rem;
  }
}

.pricing-section {
  color: #ffffff;
}

.pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 1.875rem;
  width: 100%;
}

.pricing-item {
  align-items: stretch;
  display: flex;
  flex: 0 1 20.625rem;
  flex-direction: column;
  position: relative;
  text-align: center;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-action {
  background: none;
  border: none;
  color: inherit;
}
.pricing-action:focus {
  outline: none;
}

.pricing-theme .pricing-item {
  background: rgba(255, 255, 255, 0.08);
  border: 0.0625rem solid rgba(255, 255, 255, 0.4);
  border-radius: 0.625rem;
  color: #ffffff;
  cursor: default;
  margin-bottom: 1.25rem;
  transition: border-color 0.3s, background 0.3s;
  margin: 10px;
}

.pricing-theme .pricing-item:hover {
  border: 0.0625rem solid white;
  background: rgba(255, 255, 255, 0.18);
}
.pricing-theme .pricing-title {
  font-size: 1.75rem;
  font-weight: 400;
  margin: 0.5rem 0;
  padding: 1.5rem;
  position: relative;
}
.pricing-theme .pricing-title:after {
  position: absolute;
  bottom: 0;
  left: 35%;
  height: 0.0625rem;
  width: 30%;
  background: #ffffff;
  content: "";
}
.pricing-theme .pricing-price {
  font-size: 3rem;
  font-weight: 400;
  /* padding: 1.125rem 0 0 0; */
  position: relative;
  z-index: 1;
}
.pricing-theme .pricing-currency {
  font-size: 1.625rem;
  vertical-align: super;
}
.pricing-theme .pricing-sentence {
  margin: 0 auto;
  padding: 0.875rem 1.25rem;
  font-size: 1rem;
}
.pricing-theme .pricing-feature-list {
  font-size: 1.1rem;
  list-style: none;
  margin: 0 1.875rem;
  padding: 10px 0;
}
.pricing-theme .pricing-feature {
  line-height: 1.4;
}
.pricing-theme .pricing-feature:before {
  content: "✓";
  display: inline-block;
  padding: 0 0.875rem 0 0;
  vertical-align: middle;
}
.pricing-theme .pricing-action {
  border-radius: 0.3125rem;
  flex: none;
  font-weight: 700;
  margin: auto 1.25rem 1.25rem;
  padding: 1rem 1.25rem;
  transition: background 0.3s;
}
.m-y-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-y-switch b {
  font-size: 17px;
  margin: 10px;
}
.user-q-chat {
  padding-left: 15%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.user-q-chat > div {
  background: linear-gradient(to bottom, rgb(65 96 169), rgb(18, 27, 48));
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 5px;
}
.user-q-chat > div p {
  margin-bottom: 0px;
}
.user-r-chat {
  width: 100%;
  display: flex;
  padding: 10px;
  padding-right: 15%;
}
.user-r-chat .display-goal-st-s1 {
  padding: 0px;
}
.user-r-chat .display-goal-st-s1 img {
  border-radius: 5px !important;
  object-fit: contain;
  width: auto;
}
.user-r-chat .display-goal-st-s1 p {
  margin-bottom: 0px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}
.user-r-chat .video-thumbnail-imgg2s {
  height: auto;
}
.chat-bx-mnd {
  height: 100%;
  overflow-y: scroll;
}
.chat-main-d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
