:root {
  --bgBlack2: #ffffff14;
  --blue2: rgb(40, 59, 103);
}
.video-sec-hmb {
  /* height: 80vh; */
  margin: 30px 0px;
}
.vdioe-hm-left {
  /* background-color: white; */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 15%;
  margin-top: 20%;
}
.vdioe-hm-left p {
  margin: 30px 0px 30px 0px;
  font-size: 20px;
}
.what-text-h {
  color: white;
}
.pricing-section-s4 .card-bx-h4 .light-card-s5 {
  background-color: black;
}
.colume-dis-d {
  padding-top: 20px;
  margin-left: 50px;
}
.vdioe-hm-left h1 {
  font-size: 44px;
}
.buttons-area-bx-lft {
  display: flex;
  /* justify-content: center; */
}
.buttons-area-bx-lft .ant-btn {
  margin-right: 30px;
}
.grey-clr-bx-text {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 40px 0px;
  border-radius: 5px;
  color: white;
  height: 500px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.grey-clr-bx-text p {
  padding: 10%;
  font-size: 30px;
}

.card-bx-h4 {
  padding: 15px;
  height: 100%;
}

.cards-s4-bx {
  margin: 40px 0px;
}
.cards-s4-bx .ant-typography {
  margin-bottom: 0px;
}
.card-bx-h4 > div {
  padding: 20px;
  border-radius: 5px;
  height: 100%;
}
.card-bx-h4 > div > div {
  margin-bottom: 20px;
  margin-top: -15px;
}

.card-bx-h4 > div h6 {
  margin-bottom: 20px;
}

.red-card-s4 {
  background: linear-gradient(to bottom, #ffbfc3, #ffbfc3, black);

  color: black;
}
.red-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(137.48deg, #ffbfc3 10%, black 45%, #ffbfc3 67%, black 87%);
}
.red-card-s4 > .liner-2px {
  border: 2px solid red;
  opacity: 0;
}
.red-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.red-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}
.red-card-s4 h6 {
  color: red;
}
.red-card-s4 .icon-bx-section4 span {
  background-color: #ffbfc3;

  color: red;
}

.grey-card-s4 {
  background: linear-gradient(
    to bottom,
    rgb(217, 217, 217),
    rgb(217, 217, 217),
    black
  );
  color: black;
}
.grey-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(217, 217, 217) 10%,
      black 45%,
      rgb(217, 217, 217) 67%,
      black 87%
    );
}
.grey-card-s4 > .liner-2px {
  border: 2px solid black;
  opacity: 0;
}
.grey-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.grey-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}

.grey-card-s4 .icon-bx-section4 span {
  background-color: rgb(217, 217, 217);
  color: black;
}

.green-card-s4 {
  /* background-color: rgb(202, 255, 202); */
  background: linear-gradient(
    to bottom,
    rgb(202, 255, 202),
    rgb(202, 255, 202),
    black
  );

  color: black;
}
.green-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(202, 255, 202) 10%,
      black 45%,
      rgb(202, 255, 202) 67%,
      black 87%
    );
}
.green-card-s4 > .liner-2px {
  border: 2px solid green;
  opacity: 0;
}
.green-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.green-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}
.green-card-s4 h6 {
  color: green;
}
.green-card-s4 .icon-bx-section4 span {
  background-color: rgb(202, 255, 202);

  color: green;
}
.blue-card-s4 {
  background: linear-gradient(
    to bottom,
    rgb(202, 223, 255),
    rgb(202, 223, 255),
    black
  );

  color: black;
}
.blue-card-s4 > .liner-2px {
  border: 2px solid blue;
  opacity: 0;
}
.blue-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.blue-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}
.blue-card-s4 h6 {
  color: blue;
}
.blue-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(202, 223, 255) 10%,
      black 45%,
      rgb(202, 223, 255) 67%,
      black 87%
    );
}
.blue-card-s4 .icon-bx-section4 span {
  background-color: rgb(202, 223, 255);
  color: blue;
}
.yellow-card-s4 {
  background: linear-gradient(
    to bottom,
    rgb(255, 254, 202),
    rgb(255, 254, 202),
    black
  );

  color: black;
}
.yellow-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(255, 254, 202) 10%,
      black 45%,
      rgb(255, 254, 202) 67%,
      black 87%
    );
}
.yellow-card-s4 h6 {
  color: rgb(129, 129, 0);
}
.yellow-card-s4 > .liner-2px {
  border: 2px solid rgb(129, 129, 0);
  opacity: 0;
}
.yellow-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.yellow-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}

.yellow-card-s4 .icon-bx-section4 span {
  background-color: rgb(255, 254, 202);
  color: rgb(129, 129, 0);
}
.brown-card-s4 {
  background: linear-gradient(
    to bottom,
    rgb(255, 184, 242),
    rgb(255, 184, 242),
    black
  );

  color: black;
}
.brown-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(255, 184, 242) 10%,
      black 45%,
      rgb(255, 184, 242) 67%,
      black 87%
    );
}
.brown-card-s4 h6 {
  color: rgb(255, 0, 208);
}
.brown-card-s4 > .liner-2px {
  border: 2px solid rgb(255, 0, 208);
  opacity: 0;
}
.brown-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.brown-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}

.brown-card-s4 .icon-bx-section4 span {
  background-color: rgb(255, 184, 242);
  color: rgb(255, 0, 208);
}
.sky-card-s4 {
  background: linear-gradient(
    to bottom,
    rgb(184, 255, 250),
    rgb(184, 255, 250),
    black
  );

  color: black;
}
.sky-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(184, 255, 250) 10%,
      black 45%,
      rgb(184, 255, 250) 67%,
      black 87%
    );
}
.sky-card-s4 h6 {
  color: rgb(0, 189, 177);
}
.sky-card-s4 > .liner-2px {
  border: 2px solid rgb(0, 189, 177);
  opacity: 0;
}
.sky-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.sky-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}

.sky-card-s4 .icon-bx-section4 span {
  background-color: rgb(184, 255, 250);
  color: rgb(0, 189, 177);
}
.purple-card-s4 {
  background-color: rgb(209, 184, 255);
  background: linear-gradient(
    to bottom,
    rgb(209, 184, 255),
    rgb(209, 184, 255),
    black
  );

  color: black;
}
.purple-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(209, 184, 255) 10%,
      black 45%,
      rgb(209, 184, 255) 67%,
      black 87%
    );
}
.purple-card-s4 h6 {
  color: rgb(89, 0, 255);
}
.purple-card-s4 > .liner-2px {
  border: 2px solid rgb(89, 0, 255);
  opacity: 0;
}
.purple-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.purple-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}

.purple-card-s4 .icon-bx-section4 span {
  background-color: rgb(209, 184, 255);
  color: rgb(89, 0, 255);
}
.orange-card-s4 {
  background: linear-gradient(
    to bottom,
    rgb(255, 239, 210),
    rgb(255, 239, 210),
    black
  );

  color: black;
}
.orange-card-s4 .icon-bx-section4 .btn {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(255, 239, 210) 10%,
      black 45%,
      rgb(255, 239, 210) 67%,
      black 87%
    );
}
.orange-card-s4 > .liner-2px {
  border: 2px solid rgb(255, 166, 0);
  opacity: 0;
}
.orange-card-s4 h6 {
  color: rgb(255, 166, 0);
}
.orange-card-s4:hover {
  transform: scale(1.01);
  transition: 0.4s;
}
.orange-card-s4:hover .liner-2px {
  opacity: 1;
  transition: 0.4s;
}

.orange-card-s4 .icon-bx-section4 span {
  background-color: rgb(255, 239, 210);
  color: rgb(255, 166, 0);
}
.icon-bx-section4 {
  border-color: transparent !important;
  margin-top: 20px !important;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px !important;
}
.icon-bx-section4.icon-c2-section4 {
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0px;
}
.icon-bx-section4 span {
  font-size: 18px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon-bx-section4 span:hover {
  transform: scale(1.09);
  transition: 0.4s;
}
.icon-bx-section4 span svg {
  margin-right: -2px;
}
.vdioe-hm-right {
  height: 100%;
  display: flex;
  align-items: center;
}
.light-card-s5 {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
.big-card-sec .light-card-s5 svg {
  font-size: 30px;
  margin: 40px 10px 0px 10px;
}
.big-card-sec {
  margin-top: 100px;
}
.what-w-sa-t {
  color: grey;
  margin-bottom: 30px;
}

.imgs-s1-c2 img {
  width: 50px;
  margin: 0px 10px;
  border-radius: 5px;
}

.icon-bx-section4 .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  overflow: hidden;
  height: 2.4rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 3px transparent;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #ff9b17d7 45%,
      #f9ff41 67%,
      #feb200d7 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.icon-bx-section4 #container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  background-color: black;
}

.icon-bx-section4 strong {
  z-index: 2;
  font-family: "Poppins" sans-serif;
  font-size: 16px;
  letter-spacing: 3px;
  color: #ffffff;
  text-shadow: 0 0 4px rgb(0, 0, 0);
}

.icon-bx-section4 #glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.icon-bx-section4 .circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.icon-bx-section4 .circle:nth-of-type(1) {
  background: rgba(0, 0, 1860 0.936);
}

.icon-bx-section4 .circle:nth-of-type(2) {
  background: rgba(0, 0, 1860 0.936);
}

.icon-bx-section4 .btn:hover #container-stars {
  z-index: 1;
  background-color: #212121;
}

.icon-bx-section4 .btn:hover {
  transform: scale(1.1);
}

.icon-bx-section4 .btn:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.icon-bx-section4 .btn:active .circle {
  background: #fe53bb;
}

.icon-bx-section4 #stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

.icon-bx-section4 #stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

.icon-bx-section4 #stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

.icon-bx-section4 #stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

.icon-bx-section4 #stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@media (max-width: 768px) {
  .vdioe-hm-left {
    margin: 0px 0px 50px 0px;
    padding-right: 0px;
    padding: 10px;
  }
  .vdioe-hm-left h1 {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .grey-clr-bx-text {
    height: auto;
  }
  .grey-clr-bx-text p {
    padding: 10px 5px;
    font-size: 25px;
  }

  .d-flex.so-login-links b {
    font-size: 12px;
  }
}
