:root {
  --bgBlack2: #ffffff14;
  --blue2: rgb(40, 59, 103);
}

.editor-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.editor-container .up-bx-mnbcs {
  margin-top: 10px;
}
.editor-container .up-bx-mnbcs {
  justify-content: flex-end;
}

.ant-tabs-nav::before {
  border-bottom-color: rgb(60, 60, 60) !important;
}
.img-card-cont {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.assets-bx-h1 h5 {
  color: white;
}
.connect-top-con {
  margin: 10px 0px 10px 0px;
}
.connect-top-con .ant-col {
  display: flex;
  align-items: center;
}
.left-side-container {
  height: 100%;
  width: 100px;
  min-width: 90px;
  border-right: 1px solid white;
  display: flex;
}
.sidebar {
  overflow-x: hidden;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.img-main-con-cx {
  position: relative;
}
.close-icon-bnc {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.img-main-con-cx:hover .close-icon-bnc {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.close-icon-bnc svg {
  border-radius: 50%;
  background-color: white;
  color: var(--blue2);
}
.close-icon-bnc svg:hover {
  color: white;
  background-color: var(--blue2);
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.active-task-bxk img {
  height: 100%;
}

.resizer {
  width: 2px;
  top: 0;
  right: 0;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  background-color: white;
}

.content-editor {
  width: 100%;
  height: 100%;
  background-color: rgb(61, 61, 61);
}
.left-icons-a {
  width: 80px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-left-ed {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(217, 217, 217);
  margin: 5px 10px;
  padding: 5px;
  /* background-color: #42b362; */
  width: 70px;
  border-radius: 10px;
}
.search-bx-c-cine {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* width: 100%; */
  padding: 0% 5%;
  align-items: center;
}
.icon-left-ed b {
  font-size: 11px;
}
.icon-left-ed svg {
  font-size: 18px;
  margin-bottom: 5px;
}
.icon-left-ed:hover {
  color: white;
  transition: 0.4s;
  cursor: pointer;
}
.icon-left-ed.active-i-l-ed {
  background: linear-gradient(to bottom, rgb(65 96 169), rgb(18, 27, 48));
  color: white;
}
.left-icons-nn {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
}
.start-search-bx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: black;
}
.search-main-ed {
  /* margin-top: 20px; */
  margin-left: 0px;
}
.right-in-left {
  width: 100%;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgb(60, 60, 60);
  margin-right: 10px;
}
.right-in-left input,
.right-in-left .ant-input,
.grey-input {
  background-color: rgb(42, 42, 42);
  color: white !important;
  border-color: transparent !important;
  border-radius: 5px;
}

.right-in-left input::placeholder,
.right-in-left .ant-input::placeholder,
.grey-input::placeholder {
  color: rgb(143, 143, 143);
}
.right-in-left input:focus,
.right-in-left .ant-input:focus,
.grey-input:focus {
  background-color: rgb(60, 60, 60);
  color: white;
}
.grey-input .ant-select-selector {
  background-color: rgb(42, 42, 42) !important;
  color: white;
  border-color: transparent !important;
  border-radius: 3px !important;
}
.grey-input .ant-select-arrow {
  color: white;
}
.ant-select-dropdown-hidden {
  background-color: rgb(42, 42, 42) !important;
}
.center-input-u-img {
  display: flex;
  height: 40px;

  align-items: center;
}
.grey-button {
  background-color: rgb(29, 29, 29) !important;
  color: white;
  border-radius: 3px;
  border-color: black;
  margin-left: 10px;
}
.grey-button:hover {
  background-color: black !important;
  color: white !important;
}
.videos-left-ed {
  display: flex;
  flex-wrap: wrap;
  max-height: 80%;
  overflow-y: auto;
  align-items: flex-start !important;
  /* background-color: #008300; */
}
.videos-left-ed .video-thumbnail-img {
  width: 100%;
  height: auto;
}
.videos-left-ed .video-thumbnail-img img {
  border-radius: 2px;
}
.videos-left-ed .video-card-story {
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
}
.videos-left-ed .large-play-btn {
  margin-top: -50px;
  width: 32px !important;
  height: 32px !important;
  background: transparent;
  border-color: transparent;
  background-color: rgba(240, 248, 255, 0.343);
}
.videos-left-ed .large-play-btn:hover {
  background-color: white !important;
  border-color: transparent !important;
}
.videos-left-ed .r-atc-incs {
  position: absolute;
  z-index: 20;
  color: var(--blue2);
  font-size: 16px;
}
.videos-left-ed .large-play-btn:hover .r-atc-incs {
  transform: scale(1.1);
  transition: 0.4s;
}
.videos-left-ed .ant-typography {
  margin-bottom: 0px;
  color: white;
  padding: 3px;
}
.img-hover-wc:hover {
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.tags-editor-tb {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  overflow: hidden;
}
.editor-tag-tab {
  /* background-color: rgb(19 59 207 / 50%); */
  color: white;
  border-color: transparent;
  cursor: pointer;
}
.editor-tag-tab.active-tg-wdt {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.03)
  );
  color: white;
  border-color: transparent;
  font-weight: 700;
}
.editor-tag-tab.active-tg-wdt.theme-shade-btn {
  background: linear-gradient(to bottom, var(--blue2), var(--blue2));
  color: white;
  border-color: transparent;
  font-weight: 700;
}

.editor-tag-tab.active-tg-wdt:hover {
  transform: scale(1.02);
  transition: 0.4s;
  cursor: pointer;
  color: white !important;
  border-color: transparent !important;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.15)
  ) !important;
}
.red-c {
  color: red;

  font-size: 18px;
}
.yellow-c {
  color: yellow;
  font-size: 16px;
}
.status-bx-hng {
  display: flex;
  justify-content: center;
}
.btn-sh-bcn {
  color: white;
}
.btn-sh-bcn > div {
  margin-bottom: 5px;
}
.btn-sh-bcn b {
  margin-right: 10px;
}
.close-icon-ed {
  display: flex;
  align-items: center;
  height: 100%;
  width: 1px;
}
.close-icon-ed span {
  position: absolute;
  background-color: #3b3b3b;
  padding: 2px;
  z-index: 100;
  right: 0px;
  color: white;
  border-radius: 5px;
}
.sound-thumbnail-img img {
  width: 80px;
  border-radius: 5px;
}
.sound-thumbnail-img {
  display: flex;
}
.sound-thmb-qa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  width: 100%;
}

.sound-thmb-qa p {
  margin-bottom: 0px;
  font-size: 13px;
}
.bpttpm-icn-wc {
  /* background-color: #a6a6a6; */
  display: flex;
  justify-content: flex-end;
}
.bpttpm-icn-wc svg {
  color: white;
}
.bpttpm-icn-wc svg:hover {
  color: white;
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.video-card-story audio {
  width: 100% !important;
}
.music-thmb-qa {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff14;
  justify-content: center;
  min-height: 80px;
}
.music-thmb-qa p {
  margin-bottom: 0px;
  font-size: 13px;
}
.music-thmb-qa .bpttpm-icn-wc svg {
  font-size: 20px;
  margin-bottom: 5px;
}
.music-thmb-qa .bpttpm-icn-wc svg:hover {
  color: white;
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.card-imagine-create2.editor-create-bx {
  margin-top: 10px;
}
.create-button-con .editor-tag-tab.active-tg-wdt {
  width: 100px;
  margin: 10px 0px 0px 10px;
  padding: 4px 5px;
  display: flex;
  justify-content: center;
}
.create-button-con .editor-tag-tab.active-tg-wdt:hover {
  transform: scale(1.02);
  transition: 0.4s;
  cursor: pointer;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.15)
  );
}

.create-button-con .editor-tag-tab.active-tg-wdt:active {
  background-color: #081e67;
}
.left-side-container .ant-skeleton.ant-skeleton-element .ant-skeleton-button {
  width: 100% !important;
  /* background: linear-gradient(90deg, rgb(255 255 255 / 6%) 25%, rgb(255 255 255 / 15%) 37%, rgb(255 255 255 / 6%) 63%) !important; */
}
.left-side-container .ant-skeleton.ant-skeleton-element {
  height: auto !important;
  width: 100% !important;
}
.left-side-container .eighty-w-sk {
  width: 80px !important;
}
.container8 {
  position: relative;
  --size-button: 40px;
  color: white;
}

.input8 {
  padding-left: var(--size-button);
  height: var(--size-button);
  font-size: 15px;
  border: none;
  color: #fff;
  outline: none;
  width: var(--size-button);
  transition: all ease 0.3s;
  background-color: #191a1e;
  box-shadow: 1.5px 1.5px 3px #0e0e0e, -1.5px -1.5px 3px rgb(95 94 94 / 25%),
    inset 0px 0px 0px #0e0e0e, inset 0px -0px 0px #5f5e5e;
  border-radius: 5px;
  cursor: pointer;
}

.input8:focus,
.input8:not(:invalid) {
  width: 200px;
  cursor: text;
  box-shadow: 0px 0px 0px #0e0e0e, 0px 0px 0px rgb(95 94 94 / 25%),
    inset 1.5px 1.5px 3px #0e0e0e, inset -1.5px -1.5px 3px #5f5e5e;
}

.input8:focus + .icon8,
.input8:not(:invalid) + .icon8 {
  pointer-events: all;
  cursor: pointer;
}

.container8 .icon8 {
  position: absolute;
  width: var(--size-button);
  height: var(--size-button);
  top: 0;
  left: 0;
  padding: 8px;
  pointer-events: none;
}

.container8 .icon8 svg {
  width: 100%;
  height: 100%;
}

.icons-tab {
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
  margin: 0px 5px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons-tab:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
}
.icons-tab svg {
  font-size: 17px;
}
.ant-tabs-tab-active .icons-tab {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
}
.ant-tabs-tab {
  color: rgb(255, 255, 255);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--blue2);
}

.ant-tabs-nav::before {
  border-color: transparent !important;
}
.show-content-icon {
  display: flex;
  justify-content: flex-end;
  height: 0px;
}
.show-content-icon svg {
  position: relative;
  top: -33px;
  right: 10px;
  font-size: 25px;
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}
.show-content-icon.video-ixn svg {
  top: 0px;
}
.show-content-icon svg:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
}
.show-content-icon svg:active {
  color: black;
  background-color: rgb(255, 255, 255);
  transform: scale(1) !important;
  transition: 0s;
}
.show-content-icon2 {
  display: flex;
  justify-content: space-between;
  height: 0px;
  position: relative;
  top: -15px;
  right: -10px;

  padding: 0px 5px;
}
.show-content-icon2 .icon-img-hover {
  color: var(--blue2);
  opacity: 0.6;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 3px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-content-icon2 .icon-img-hover svg {
  font-size: 16px;
}
.show-content-icon2.video-ixn svg {
  top: 0px;
}
.show-content-icon2 .icon-img-hover:hover {
  transform: scale(1.1);
  transition: 0.5s;
  opacity: 1;
  cursor: pointer;
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
}
.show-content-icon2 svg:active {
  color: black;
  background-color: rgb(255, 255, 255);
  transform: scale(1) !important;
  transition: 0s;
}
.video-card-story .show-hover {
  opacity: 0;
}
.video-card-story:hover .show-hover {
  opacity: 1;
  transition: 0.7s;
}
.content-ed-main {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  position: relative;
}
.content-img-bx .content-img-m {
  max-width: 80%;
  max-height: 420px;
  object-fit: contain;
  border-radius: 5px;
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}
.blank-page-imhg-c .content-img-m {
  max-width: 100%;
  max-height: 420px;
}
.active-goals-tc .blank-page-imhg-c .content-img-m {
  width: 100%;
  border-radius: 5px;
}
.blank-page-imhg-c {
  position: relative;
}
.bx-iucon-sx {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 420px;
  color: white;
  overflow: hidden;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
}
.content-img-bx .video-container {
  max-width: 100%;
  max-height: 420px;
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}
.blank-page-video-c {
  max-width: 80%;
  max-height: 420px;
}
.content-img-bx {
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.cards-buttons-smal {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 20px; */
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px;
}
.coonetnt-img-bx-m1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cards-buttons-smal button {
  margin: 5px;
}
.t-r-icons-x {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.t-r-icons-x .icon-left-ed {
  padding: 5px;
  width: 42px;
  border-radius: 5px;
  margin: 0px 0px 5px 0px;
}

.center-input-u-img input {
  background-color: rgb(29, 29, 29);
  color: white;
  margin-bottom: 0px;
  padding: 8px 8px;
}
.center-input-u-img input::placeholder {
  color: rgb(143, 143, 143);
}
.center-input-u-img input:focus {
  background-color: rgb(90, 90, 90);
  color: white;
}
.start-mn-mx .center-input-u-img input:focus {
  background-color: rgb(90, 90, 90);
  color: white;
}
.start-card-bx {
  background-color: rgb(29, 29, 29);
  padding: 15px;
  border-radius: 10px;
  margin: 0px 10px;
  width: 200px;
  display: flex;
  flex-direction: column;
  color: white;
  /* box-shadow: rgba(255, 255, 255, 0.1) 0px 10px 20px; */
}
.start-card-bx:hover {
  transform: scale(1.05);
  transition: 0.5s;
  cursor: pointer;
  background-color: var(--blue2);
  /* box-shadow: rgb(0, 0, 255) 0px 10px 15px -5px; */
  color: rgb(255, 255, 255);
}
.cards-buttons-smal .start-card-bx:hover {
  background-color: black;
  box-shadow: none;
}
.cards-buttons-smal .start-card-bx {
  margin: 5px;
  padding: 15px;
}
.cards-buttons-smal .start-card-bx p {
  margin-bottom: 0px;
}
.start-card-bx:active {
  background-color: black;
}
.start-card-bx:hover svg {
  color: white;
  transition: 0.5s;
}
.start-card-bx > div {
  display: flex;
  align-items: flex-start;
}
.start-card-bx svg {
  color: white;
  font-size: 25px;
  margin-right: 15px;
  /* background-color: white; */
  border-radius: 5px;
}
.loader-c-sd svg {
  position: relative;
}
.start-card-bx h5 {
  text-align: center;
  /* color: var(--blue2); */
}
.start-card-bx p {
  text-align: center;
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 5px;
}
.cards-buttons-smal .start-card-bx svg {
  font-size: 22px;
}
.cards-buttons-smal .start-card-bx h5 {
  font-size: 17px;
}

.blank-area-box {
  height: 350px;
  width: 60%;
  min-width: 500px;
  background-color: #878787;
}
.start-mn-mx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: black;
}
.cards-bx-str {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 700px;
}
.editor-container .ant-select .ant-select-arrow {
  color: white;
}
.start-inputo {
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-bottom: -20px;
  justify-content: flex-start !important;
}
.home-icon0-t {
  color: grey;
  font-size: 30px;
  margin-right: 10px;
  padding: 2px;
  border-radius: 5px;
}
.home-icon0-t:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
  color: white;
  background-color: var(--blue2);
}
.blank-area-box .create-tab {
  background-color: transparent;
  box-shadow: none;

  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
}
.blank-area-box .input-right-radius .ant-select-selector {
  border-radius: 5px !important;
}
.blank-area-box .create-button-con {
  width: 300px;
  margin: 10px auto;
}
.create-img-bx {
  height: 100%;
  width: auto;
  border-radius: 5px;
}
.blank-area-box {
  display: flex;
  justify-content: center;
}
.img-loader-bx {
  width: 300px;
  height: 300px;
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
  background-color: grey;
  border-radius: 5px;
  max-width: 300px;
}
.img-loader-bx .ant-skeleton-image {
  width: 300px !important;
  height: 300px !important;
}
.img-loader-bx .ant-progress-line {
  position: relative;
  top: -40px;
  padding: 10px;
}
.failed-text-c {
  color: rgb(69, 0, 0);
  margin-top: 10px;
  margin-bottom: -10px;
}
.info-elrt-m.ant-alert-info {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: grey;
  color: white;
  max-width: 400px;
  margin-top: 20px;
}
.info-elrt-m.ant-alert-info .ant-alert-message {
  color: white;
}
.info-elrt-m.ant-alert-info .ant-alert-icon {
  color: white !important;
}
.close-icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.close-icon svg {
  padding: 3px;
  border-radius: 3px;
  background-color: black;
  color: white;
  margin-bottom: 5px;
  font-size: 20px;

  margin-top: -10px;
}

.close-icon svg:hover {
  color: black;
  background-color: white;
  transition: 0.4s;
  cursor: pointer;
}
.close-icon.plus-icon svg {
  margin-top: 10px;
  color: black;
  background-color: white;
}
.close-icon.plus-icon svg:hover {
  background-color: black;
  color: white;
}
.start-card-bx.active-icn-bb {
  background-color: black;
}
.right-icon-edit-c {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 120px;
  border-radius: 0px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  animation: right-p 0.5s ease;
  z-index: 10;
}
@keyframes right-p {
  0% {
    right: -50px;
  }
  100% {
    right: 0;
  }
}
.right-icon-edit-c > div {
  color: white;
  /* background-color: black; */
  margin: 5px 5px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-icon-edit-c > div.actt {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
}
.right-icon-edit-c > div:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
}

.blank-area-box.video-gene .create-tab {
  display: flex;
  flex-direction: row !important;
  background-color: #081e67;
  align-items: center;
}
.blank-area-box.video-gene .duration-box-m {
  margin-top: 0px;
}
.blank-area-box.video-gene .create-button-con {
  width: 100%;
}
.name-tg-b {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}
.name-tg-b .ant-tag {
  text-transform: capitalize;
  margin-right: 0px;
}

.videos-left-ed .ant-ribbon-text {
  text-transform: capitalize;
}

.name-tg-b input,
.name-tg-b .ant-input {
  background-color: #0e0e0e;
}
.videos-left-ed .ant-ribbon-wrapper:hover .ant-ribbon {
  display: none;
}
.delete-icon-c {
  display: flex;
  justify-content: flex-end;
  /* margin-top: -10px; */
  height: 1px;
}
.delete-icon-c b {
  display: none;
  position: absolute;
  z-index: 600;
  top: 10px;
  right: 5px;
  /* background-color: #BC1823; */
}
.videos-left-ed .ant-ribbon-wrapper {
  width: 100%;
}
.videos-left-ed .ant-ribbon-wrapper:hover .delete-icon-c b {
  display: inline;
  color: white;
}
.videos-left-ed .delete-icon-c b :hover {
  color: red;
  cursor: pointer;
  transform: scale(1.1);
}
.sound-thmb-qa.writing-style-input {
  padding-left: 0px;
}
.writing-style-input .name-tg-b {
  flex-direction: column;
}
.play-icon-coive0 {
  min-width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0e0e0e;
  border-radius: 5px;
}
.play-icon-coive0 svg {
  font-size: 25px;
}
.play-icon-coive0 svg:hover {
  color: var(--blue2);
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
}
.play-icon-coive0 svg:active {
  color: black;
}
.block-type-c {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: 100%;
}
.block-add-tbk {
  margin: 30px auto;
}
.block-add-tbk td {
  padding: 10px;
}
.icon-planing-c {
  display: flex;
  align-items: flex-end;
}

.icon-planing-c h5 {
  margin-bottom: 0px;
  display: flex;
  height: 20px;
  align-items: flex-end;
  font-size: 13px !important;
  text-transform: capitalize;
  margin-left: 5px;
}

.icon-planing-c h5 svg {
  font-size: 14px !important;
  margin-right: 5px;
}
.cards-buttons-smal.planing-goals .start-card-bx {
  width: auto;
}
.ant-popover-inner {
  max-width: 300px;
}
.planing-goals.cards-buttons-smal .start-card-bx {
  padding: 8px;
}
.start-card-bx.red-bg-gr {
  background: linear-gradient(-45deg, #9e0000, #330000);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}
.start-card-bx.green-bg-gr {
  background: linear-gradient(-45deg, #008300, #ccfffa);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}
.start-card-bx.yellow-bg-gr {
  background: linear-gradient(-45deg, #caca00, #828201);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.icon-planing-c > img {
  /* width: 20px; */
  height: 40px;
  margin: 0px 3px;
  object-fit: cover;
}
.close-icon.space-bb {
  display: flex;
  justify-content: space-between;
}
.close-icon.space-bb > span {
  top: -10px;
  position: relative;
  color: white;
}
/* .bx-iucon-sx {
  background-color: #081e67;
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -400px;

} */
.duration-box-m.start-inputo {
  justify-content: space-between !important;
}
.center-input-u-img .start-card-bx {
  position: relative;
  border-radius: 5px;
}
.loadin-videos-more-c {
  width: 300px;
  padding-top: 10px;
  color: white;
  display: flex;
  justify-content: center;
}
.home-save-ib {
  display: flex;
  /* flex-direction: column; */
}

.editor-tag-tab.active-tg-wdt.save-btn-c {
  margin-right: 10px;
  border-radius: 3px;
}
.projects-con:hover {
  background-color: #081e67;
  cursor: pointer;
  transition: 0.4s;
  border-color: #081e67;
}
.projects-con.active-card-pro {
  background-color: #081e67;
  border-color: #081e67;
}
.slider-main0div {
  width: auto;
  border: 4px solid red;
  margin-right: 10px;
}
.content-editor .slick-prev:before {
  color: white;
  left: 30px;
  position: absolute;
}
.content-editor .slick-next:before {
  color: white;
  right: 120px;
  position: absolute;
}
.bx-form-brand.no-radius-input div {
  width: 100%;
}
.video-thumbnail-imgg.img-top-bx {
  width: 100%;
  justify-content: center;
  display: flex;
}
.video-thumbnail-imgg.img-top-bx img {
  max-width: 400px;
  border-radius: 5px;
  margin: 0 auto;
}
.audio-player-mn-bx {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  padding-bottom: 30px;
  justify-content: center;
}
.arrow-pro-c {
  display: flex;
  justify-content: space-between;
  color: white;
}
.preview-butn-bx {
  background-color: black;
}
.preview-butn-bx .ant-tag {
  margin-right: 0px;
}
.mapboxgl-ctrl {
  display: none;
}
.mapboxgl-ctrl-bottom-left {
  display: none;
}
.user-name-acc-c.user-name-acc-c21 {
  margin: 20px 0px;
}
.page {
  width: 100%;
  height: 100vh;
}
.top-bar-page-c {
  background-color: black;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  width: 100%;
  padding: 5px 10px;
}
.top-inner-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-bx-draw {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1024px;
}

.canvas-drwa-img {
  /* width: 100%; */
  height: 100%;
  min-height: 200px;
  background-color: rgba(0, 0, 0, 0.443);

  position: absolute;
  border: 1px solid rgb(151, 151, 151);
  /* background-color: white; */
  z-index: 10000;
}
.position-circle {
  border: 1px solid rgb(108, 108, 108);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  right: 5px;
  top: 5px;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-image-b {
  width: 150px;
  border-radius: 10px;

  max-height: 150px;
}
.brush-size-d {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.brush-size-d b {
  margin-right: 10px;
}
.profile-edit-page.left-dev-form {
  color: white;
}
.profile-edit-page.left-dev-form .ant-input-affix-wrapper {
  background-color: transparent;
  border-color: rgb(74, 74, 74);
}
.profile-edit-page.left-dev-form .ant-input-affix-wrapper svg {
  color: white;
}
.profile-edit-page.left-dev-form b {
  padding-top: 10px;
  padding-right: 10px;
}
.up-sel-b-a .space-between {
  align-items: center;
  margin-top: 30px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.alert-created {
  color: yellow;
  padding: 5px;
  border-radius: 5px;
  background: rgba(0, 170, 255, 0.2);
  /* margin-bottom: -10px; */
  /* margin-top: 10px; */
  /* margin-left: 30px; */
}

.profile-imgc {
  width: 100px;
  border-radius: 5px;
}
.space-btns-c {
  display: flex;
  flex-wrap: wrap;
}
.search-main-ed .block-type-c {
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-top: 10px;
}

.load-more-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.show-content-icon2.show-hover {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #008300;
}
.show-content-icon2.show-hover .icon-img-hover {
  position: relative;
  z-index: 50;
  /* right: 5px; */
}
.video-card-story.new-pro-d {
  display: flex;
  justify-content: center;
  /* background-color: rgba(240, 248, 255, 0.143); */
  padding: 5px;
  align-items: center;
}
.video-card-story.new-pro-d svg {
  margin-left: 5px;
}
.video-card-story.new-pro-d:hover {
  background-color: var(--blue2);
}
.story-pro-name-c {
  display: flex;
  color: white;
  align-items: center;
  border-bottom: 1px solid grey;
}
.home-to-name {
  display: flex;
  align-items: center;
}
.top-20-modal .videos-left-ed {
  max-height: 80vh;
}
.nested-right-c {
  display: flex;
  flex-direction: column;
}
.img-bx-c-com img {
  height: 35px;
  object-fit: contain;
}
.center-input-u-img.input-center-c {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.center-input-u-img.input-center-c .start-card-bx > div {
  padding: 8px;
}
.top-inner-c.top-inner-bl {
  padding: 5px 10px;
}
.or-bx-canvas {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.save-pro-bx h4 {
  font-size: 18px;
}
.save-pro-bx .video-card-story.projects-con {
  margin-right: 0px !important;
}
.save-pro-bx .start-mn-mx {
  flex-direction: row;
}
.back-icon-bx {
  margin-bottom: 10px;
}
.back-icon-bx svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.5s;
}
.edit-icn-c {
  margin-left: 5px;
}
.query-name-bx-c {
  display: flex;
  justify-content: space-between;
}
.edit-icn-c:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.5s;
}
.failed-delte-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 118px;
  color: rgb(207, 0, 0);
}
.red-button {
  background-color: rgb(150, 0, 0);
  border-color: rgb(150, 0, 0);
  color: white;
}
.red-button:hover {
  background-color: rgb(121, 1, 1) !important;
  border-color: rgb(121, 1, 1) !important;
  color: white !important;
}
.red-button:active {
  background-color: rgb(219, 0, 0) !important;
  border-color: rgb(219, 0, 0) !important;
  color: white !important;
}
.delet-btns-h {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.bx-form-brand h6 {
  width: 100%;
}
.delet-btns-h .ant-btn {
  margin-left: 10px;
  margin-top: 20px;
}
.api-key-input-c {
  margin: 30px 0px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.green-button {
  background-color: rgb(45, 113, 0);
  border-color: rgb(45, 113, 0);
  color: white;
}
.card-bx-mnbc {
  padding: 5px;
  height: 100%;
}
.card-bx-mnbc div {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-bx-mnbc div:hover {
  background: var(--blue2);
  transition: 0.4s;
  cursor: pointer;
}
.card-bx-mnbc a {
  color: white;
  text-decoration: none;
}
.card-bx-mnbc div:hover svg {
  margin-right: -5px;
  transition: 0.4s;
}
.card-bx-mnbc h6 b {
  color: #00fc00;
}
.full-w-table table {
  width: 100%;
}
.full-w-table tr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.no-heigh-div-c.editor-container.special-card-c {
  height: auto;
}
.no-heigh-div-c.editor-container.special-card-c .card21 {
  margin-top: 0px;
}
/* .card-box-center-c{
  display: flex;
  height: 100%;
} */

.card-box-center-c h6 {
  margin-bottom: 10px;
  margin-left: 5px;
}
.green-button:hover {
  background-color: rgb(34, 85, 1) !important;
  border-color: rgb(34, 85, 1) !important;
  color: white !important;
}
.green-button:active {
  background-color: rgb(45, 113, 0) !important;
  border-color: rgb(45, 113, 0) !important;
  color: white !important;
}
.tags-date-area {
  display: flex;
  justify-content: space-between;
}
.tags-date-area .ant-tag svg {
  color: white;
}
.img-2-bcs {
  border-radius: 5px;

  margin: 10px 0px;
}
.edit-grid-boxs {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 4px 12px;
  /* margin-bottom: 10px; */
  /* background-color: rgba(0, 0, 0, 0.443); */
}
.edit-grid-boxs .ant-col {
  padding: 4px !important;
}
.row-grd-bx {
  margin-bottom: 0px;
  opacity: 0.4;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.row-grd-bx.active-bx {
  background-color: var(--blue2);
  opacity: 0.7;
  border-color: transparent;
}
.row-grd-bx:active {
  background-color: black;
  /* transform: scale(1.05); */
}
.gen-icons-img-c {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 120;
}
.grid-query-bx-x {
  margin-top: 10px;
  padding: 5px;
  align-items: flex-start;
  display: flex;
  justify-content: center;
}
.fp-overflow {
  height: 100%;
}
.fp-watermark {
  display: none;
}
.carousel-bx-c {
  position: absolute;

  z-index: 90;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  left: 10px;
  top: 80px;
  /* padding: 5px; */
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 5px 0px;
}
.carousel-bx-c img {
  width: 40px;
  margin: 4px 8px;
  border-radius: 5px;
  border: 2px solid transparent;
}
.carousel-bx-c img:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
  /* transition: ; */
}
.active-imhg-b img {
  transform: scale(1.2);
  border: 2px solid var(--blue2);
  transition: all 0.4s ease-in-out;
}
.carousel-bx-c::-webkit-scrollbar {
  width: 1px;
}

/* Track */
.carousel-bx-c::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.carousel-bx-c::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.carousel-bx-c::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.left-alert-bcs {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 300px;
}
.left-alert-bcs .ant-alert {
  width: 250px;
}
.tags-date-area table td {
  padding: 5px 10px;
}
.progress-bc {
  position: absolute;
  z-index: 110;
  right: 0px;
  /* background-color: #008300; */
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-bc.failed-bcv {
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 7px;
}
.video-container.video-container-round {
  height: 100%;
}
.content-ed-main .video-container-round > div > video {
  margin-bottom: -56px !important;
}
.video-thumbnail-img.img-2-sx {
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}
.video-thumbnail-img.img-2-sx img {
  border-radius: 0px;
}
.video-thumbnail-img.img-2-sx.audio-bx-blank {
  padding: 15px;
  color: white;
  display: flex;
  align-items: center;
  background-color: black;
}
.video-thumbnail-img.img-2-sx.audio-bx-blank > div > img {
  width: 200px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
  margin-right: 10px;
}
.sound-blank-q-play {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 200px;
  padding-left: 10px;
}
.sound-blank-q-play .ant-typography {
  margin-bottom: 20px;
  color: white !important;
}
.editor-container .create-tab {
  background-color: black;
  border-radius: 0px;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  /* margin-top: 5vh; */

  margin-top: 48px;
}
.editor-container .geneated-main-create {
  background-color: black;
  border-radius: 0px;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: 48px;
}
.editor-container .create-tab-main {
  padding: 0px;
}
.top-create-ed {
  position: absolute;
  background-color: black;
  width: 100%;
  color: white;
  padding: 3px 10px;
  z-index: 200;
}
.planing-goals.start-goals-cs {
  flex-direction: column;
}
.gaols-bx-start {
  display: flex;
  flex-wrap: wrap;
}
.task-d .checkmark {
  margin: 5px;
}
.created-task-d {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.icon-planing-c.icon-planing-c1 {
  display: flex;
}
p.start-g-desc {
  width: 200px;
  margin: 20px 0px;
}
.display-goal-st-s {
  padding: 10px;
}
.display-goal-st-s .blank-page-imhg-c img {
  max-width: 500px;
  border-radius: 5px;
  margin: 0 auto;
}
.display-goal-st-s .blank-page-imhg-c {
  display: flex;
  justify-content: center;
}
.icon-img-hover.actibe-brain-id {
  background-color: var(--blue2);
  color: white;
}
.img-active-brain img {
  /* width: 100px; */
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid white;
  height: 40px;
}
.img-active-brain {
  display: flex;
  flex-direction: row-reverse;
}
.img-active-brain .clear-icon {
  margin-right: 5px;
  margin-left: 0px;
  opacity: 0;
  cursor: pointer;
}
.create-input-fvc2 .main-search-cine {
  transition: 0.5s all ease-in-out;
}
.img-active-brain:hover .clear-icon {
  opacity: 1;
  transition: 0.4s;
}
.create-input-fvc2.popup-input .main-search-cine {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 54px 55px,
    rgba(0, 0, 0, 0.32) 0px -12px 30px, rgba(0, 0, 0, 0.32) 0px 4px 6px,
    rgba(0, 0, 0, 0.37) 0px 12px 13px, rgba(0, 0, 0, 0.19) 0px -3px 5px;
}
.create-input-fvc2 {
  position: relative;
  top: 0;
}
.create-input-fvc2.popup-input {
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  top: 70px;
  transition: all ease-in-out 0.7s;
}

.taks-card-mn {
  padding: 10px;
  border-radius: 5px;
  height: 100%;
  /* background: linear-gradient(to bottom, #07174e, #040c28); */
  /* color: white; */
}

.card-tsk {
  position: relative;
  width: 100%;
  height: 150px;
  background: lightgrey;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 1s ease-in-out;
}

.background-tsk {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, #07174e, #040c28);
}

.logo-tsk {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  transition: all 0.6s ease-in-out;
}
.task-name-bnm {
  position: absolute;
  bottom: 10px;
  left: 0;
  /* transform: translate(50%, 0%); */
  transition: all 0.6s ease-in-out;
  text-align: center;
  width: 100%;
}
.task-name-bnm1 {
  position: absolute;
  top: 10px;
  right: 10px;
  /* transform: translate(50%, 0%); */
  transition: all 0.6s ease-in-out;
  z-index: 100;
}
.card-tsk:hover .task-gear-i {
  position: absolute;
  top: 10px;
  right: 10px;
  /* transform: translate(50%, 0%); */
  transition: all 0.6s ease-in-out;
  z-index: 120;
  display: block;
  color: white;
  opacity: 1;
}
.card-tsk .task-gear-i {
  opacity: 0;
  display: none;
}
.card-tsk .task-gear-i svg:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.card-tsk:hover .task-name-bnm,
.card-tsk:hover .task-name-bnm1 {
  display: none;
}

.logo-tsk .logo-svg-tsk {
  fill: white;
  width: 30px;
  height: 30px;
}

.icon-tsk {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-tsk .svg-tsk {
  fill: rgba(255, 255, 255, 0.797);
  /* width: 100%; */
  transition: all 0.5s ease-in-out;
  font-size: 16px;
}

.box-tsk {
  position: absolute;
  padding: 10px;
  text-align: right;
  font-size: 8px;
  color: white;
  background: rgba(255, 255, 255, 0.389);
  border-top: 2px solid rgb(255, 255, 255);
  border-right: 1px solid white;
  border-radius: 10% 13% 42% 0%/10% 12% 75% 0%;
  box-shadow: rgba(100, 100, 111, 0.364) -7px 7px 29px 0px;
  transform-origin: bottom left;
  transition: all 1s ease-in-out;
}

.box-tsk::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.box-tsk:hover .svg-tsk {
  fill: white;
}

.box1-tsk {
  width: 70%;
  height: 70%;
  bottom: -70%;
  left: -70%;
}

.box1-tsk::before {
  background: radial-gradient(
    circle at 30% 107%,
    #1749ed 0%,
    #123cc7 5%,
    #0a2067 60%,
    #07174e 90%
  );
}

.box1-tsk:hover::before {
  opacity: 1;
}

.box1-tsk:hover .icon-tsk .svg-tsk {
  filter: drop-shadow(0 0 2px white);
}

.box2-tsk {
  width: 50%;
  height: 50%;
  bottom: -50%;
  left: -50%;
  transition-delay: 0.2s;
}

.box2-tsk::before {
  background: radial-gradient(
    circle at 30% 107%,
    #1749ed 0%,
    #123cc7 5%,
    #0a2067 60%,
    #07174e 90%
  );
}

.box2-tsk:hover::before {
  opacity: 1;
}

.box2-tsk:hover .icon-tsk .svg-tsk {
  filter: drop-shadow(0 0 2px white);
}

.box3-tsk {
  width: 30%;
  height: 30%;
  bottom: -30%;
  left: -30%;
  transition-delay: 0.4s;
}

.box3-tsk::before {
  background: radial-gradient(
    circle at 30% 107%,
    #1749ed 0%,
    #123cc7 5%,
    #0a2067 60%,
    #07174e 90%
  );
}

.box3-tsk:hover::before {
  opacity: 1;
}

.box3-tsk:hover .icon-tsk .svg-tsk {
  filter: drop-shadow(0 0 2px white);
}

.box4-tsk {
  width: 10%;
  height: 10%;
  bottom: -14%;
  left: -14%;
  transition-delay: 0.6s;
}

.card-tsk:hover {
  transform: scale(1.05);
  border-color: transparent;
}

.card-tsk:hover .box-tsk {
  bottom: -1px;
  left: -1px;
}

.card-tsk:hover .logo-tsk {
  transform: translate(0, 0);
  bottom: 5px;
  right: 5px;
}
.card-tsk:hover .logo-tsk img {
  width: 30px;
  transition: 0.5s;
}
.logo-tsk img {
  width: 80px;
  border-radius: 10px;
}
.task-name-bnm {
  color: white;
}
.txt-title-c {
  position: relative;
  z-index: 130;
}
.active-task-bxk {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.active-task-bxk > img {
  width: 80px;
  border-radius: 5px;
  margin: 0 auto;
}

.active-goals-tc {
  margin: 50px 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.active-goals-tc21 {
  /* max-height: 80vh; */
  /* overflow-y: auto; */
  padding: 10px 5%;
}
.assets-bx-h1 {
  height: 85vh;
  width: 100%;
  overflow-y: auto;
}

.arrow-back-task-g {
  font-size: 20px;
  color: white;
  margin-bottom: 20px;
}
.arrow-back-task-g:hover {
  transition: 0.4s;
  cursor: pointer;
  transform: scale(1.1);
}
.display-goal-st-s {
  width: 380px;
  display: flex;
  padding: 15px;
  align-items: center;
  /* overflow: hidden; */
}

.meta-title-cn {
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
.arrow-right-c {
  color: white;
  margin-left: 15px;
  margin-right: -15px;
}
.display-goal-st-s .new-icons-img-voice {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.display-goal-st-s .new-icons-img-voice img {
  width: 100px;
  margin-bottom: 10px;
}
.display-goal-st-s1 {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  align-items: center;
  border-radius: 5px;
}

.grey-input.main-search-cine {
  background-color: #1d1d1d;

  padding: 8px 10px;
}
.grey-input.main-search-cine:focus {
  background-color: var(--blue2);
}
.task-name-bnm .ant-typography p {
  margin-bottom: 0px;
  color: white;
}
.task-name-bnm .ant-typography {
  margin-bottom: 0px;
  color: white;
  font-size: 12px;
  padding: 0px 5px;
}
.start-icons-bx {
  display: flex;
}
.video-thumbnail-imgg {
  position: relative;
  width: 100%;
  background-color: black;
}
.top-right-popup.progress-card-title {
  left: 10px;
}
.top-right-popup.progress-card-title span {
  background-color: black;
  font-size: 11px;
  text-transform: none;
}
.top-right-popup.progress-card-title .icon-q {
  position: relative;
  z-index: 120;
  background-color: black;
  border-radius: 50%;
  left: 2px;
  font-size: 20px;
  padding: 2px;
}
.search-bx-c-cine .ant-badge-count {
  width: 16px;
  height: 16px;
  min-width: 16px;
  line-height: 15px;
  font-size: 10px !important;

  border-color: transparent;
}
.max-90-vh {
  max-height: 85vh;
  overflow-y: auto;
}
.card-bx-mn-list {
  padding: 5px;
}
.display-goal-stgh {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.bg-text-clr {
  background: rgba(0, 0, 0, 1);
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
}
.display-goal-stgh .video-thumbnail-imgg img {
  width: 200px;
  object-fit: cover;
}
.display-goal-stgh .video-thumbnail-imgg {
  background-color: transparent;
  width: auto;
}
.list-inner-bx-ui {
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
}
.list-inner-bx-ui > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.video-thumbnail-imgg img {
  width: 100%;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
  border-radius: 5px;
  background: linear-gradient(to bottom, #181818, #000000);
  position: relative;
}
.video-thumbnail-imgg .compute-img-tbl {
  min-height: auto;
  width: 30px;
  background: transparent !important;
}
.center-list-bx-c {
  display: flex;
  /* background-color: black; */
  padding: 5px;
  border-radius: 5px;
  align-items: center;
}

.video-thumbnail-imgg.height-img-mx img {
  width: 100%;
  max-height: 800px;
  min-height: 180px;
}
.icons-bx-ri-vd2s {
  position: absolute;
  right: 10px;
  /* left: 10px; */
  bottom: 10px;
  /* top: 10px; */
  display: flex;
  justify-content: center;

  align-items: center;
}
.icons-bx-ri-vd2s svg {
  /* color: var(--blue2); */
  font-size: 17px;
}
.icons-bx-ri-vd2s .ant-btn {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 1px;
  border-color: black;
  color: white;
}
.icons-bx-ri-vd2s .ant-btn svg {
  margin-top: -4px;
}
.create-input-fvc {
  display: flex;
  flex-direction: column;
}
.create-input-fvc2 {
  display: flex;
  align-items: center;
}
.create-input-fvc .icon-left-ed {
  width: auto;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 5px;
}
.create-input-fvc .icon-left-ed svg {
  font-size: 16px;
}
.home-img-card {
  width: 100%;
  height: auto;
  padding: 5px;
}
.blank-page-imhg-cgh {
  width: 100%;
  height: 100%;
}
.blank-page-imhg-cgh img {
  width: 100%;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
  border-radius: 5px;
}
.video-thumbnail-imgg:hover {
  transform: scale(1.02);
  transition: 0.4s;
  cursor: pointer;
}
.video-thumbnail-imgg2s {
  position: absolute;
  z-index: 500;
  background-color: black;
  /* border: 1px solid grey; */
  transition: all 0.6s ease-in-out;
  margin-left: -10px;
  height: 100%;

  /* background-color: #008300; */
  /* width: 150%;
  padding: 10px;
  margin-left: -30%; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.video-thumbnail-imgg2s .video-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  /* background-color: #008300; */
  /* background-color: var(--bgBlack2); */
}
.video-thumbnail-imgg2s audio {
  width: 100% !important;
}
.start-icons-bx.less-width-bx {
  display: flex;

  align-items: center;
}
.start-icons-bx.less-width-bx .icon-left-ed {
  width: 34px;
  height: 34px;
  justify-content: center;
  margin: 0px 5px;
  display: flex;
}
.start-icons-bx.less-width-bx .icon-left-ed svg {
  margin-bottom: 0px;
}
.progress-bc .ant-progress-inner {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.progress-bc .ant-progress-circle-path {
  stroke: none !important;
}
.progress-bc .ant-progress-text {
  color: white !important;
}
.header-create-c {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px 2%;
}
.bottom-popup {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}
.bottom-popup span {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
  text-transform: capitalize;
}
.top-right-popup {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;

  right: 10px;
}
.top-center-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
}
.bottom-right-popup {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
}
.top-right-popup2 {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;

  right: 10px;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 5px;
  text-transform: capitalize;
  background-color: rgba(255, 255, 255, 0.289);
  color: black;
}
.top-left-popup {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;

  left: 10px;
}
.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-left-popup {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;

  left: 10px;
}
.bottom-left-popup .ant-tag {
  position: relative;
  z-index: 120;
}
.bottom-left-popup > span {
  text-transform: capitalize;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
}
.top-right-popup > span {
  text-transform: capitalize;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
}
.c-type-icn b {
  background-color: #0000001f;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  border-radius: 5px;
  text-transform: capitalize;
}
.top-center-popup.top-2-card-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-center-popup.top-2-card-c > span {
  margin-bottom: 10px;
}
/* .c-type-icn b {
  text-transform: capitalize;
  background: -webkit-linear-gradient(180deg,white, #9e0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.top-center-popup > span {
  text-transform: capitalize;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
}
.display-goal-st-s1:hover .hide-hover {
  display: none;
}

.show-hover {
  display: none;
  position: relative;
  z-index: 100;
}
.display-goal-st-s1:hover .show-hover {
  display: inline;
  opacity: 1;
}
.top-right-popup .show-hover svg,
.circular-icon {
  font-size: 24px;
  /* margin-left: 5px; */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 3px;
  padding: 4px;
}
.hover:hover {
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 52px;
  margin-top: -20px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
.edit-block-modal > div {
  margin: 20px 0px;
}
.block-s-c {
  display: flex;
  align-items: center;
}
.block-s-c > svg {
  font-size: 22px;
  margin-right: 10px;
}
.block-s-c > svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.attach-popup-c {
  position: absolute;
  right: 0;
  z-index: 100;
  cursor: pointer;
  background-color: black;
  padding: 5px;
  font-size: 25px;
  border-radius: 5px;
}
.attach-popup-c:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.video-thumbnail-imgg .attach-popup-c {
  margin-left: 5px;
}
.right-area-det-m {
  /* background-color: rgba(255, 255, 255, 0.1); */
  height: 100%;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.tags-date-area.right-area-det-m td {
  padding: 10px;
}
.tags-date-area.right-area-det-m {
  margin: 20px 0px;
}
.active-task-bxk .show-hover {
  display: inline;
}
.active-task-bxk .coonetnt-img-bx-m1 {
  position: relative;
}
.active-task-bxk .top-right-popup {
  position: relative;
  display: flex;
  justify-content: flex-end;
  right: 0;
  height: 0px;
  margin-top: -24px;
}

.active-task-bxk .top-right-popup .show-hover svg,
.active-task-bxk .circular-icon {
  background-color: rgba(255, 255, 255, 0.689);
  color: black;
  /* margin-bottom: 20px; */
}
.image-top-r {
  position: relative;
  top: -40px;
}
.voice-top-r {
  /* width: 100%; */
  position: absolute;
  top: -20px;
  right: 0px;
}

.cat-hover-det1 {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;

  padding: 12px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.484);
}
.category-text-p {
  color: white;
}
.cat-hover-det {
  /* opacity: 0; */
  position: absolute;
  background-color: rgba(0, 0, 0, 0.484);
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  bottom: 0;
  border-radius: 5px;
  padding: 10px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-goal-st-s1:hover .cat-hover-det {
  opacity: 1;
  transition: 0.4s;
}
.display-goal-st-s1:hover .cat-hover-det1 {
  opacity: 0;
}
.category-text-d {
  color: white;
  margin-top: 20px;
  text-transform: capitalize;
  font-size: 20px;
}
.no-heigh-div-c.editor-container {
  max-width: 500px;
  max-height: 250px;
  margin: 0 auto;

  width: 100%;
}
.no-heigh-div-c.editor-container .ant-btn {
  margin-top: 0px !important;
}
.download-show-img img {
  width: 100%;
  border-radius: 5px;
}
.black-query-bc {
  font-size: 18px;
  text-transform: capitalize;
}
.black-query-bc {
  display: flex;
  justify-content: space-between;
}
.black-query-bc .ant-tag {
  color: white;
}
.black-query-bc .ant-tag:hover {
  transform: scale(1.03);
  transition: 0.4s;
  cursor: pointer;
}
.black-query-bc .ant-tag:active {
  background-color: gray;
}
.images-main-p .video-thumbnail-imgg img {
  min-height: 100px;
  height: 100%;
}
.img-height-adjusted .video-thumbnail-imgg {
  height: 100%;
}
.img-height-adjusted .video-thumbnail-imgg img {
  min-height: 50px;
  height: 100%;
}
.related-bx-d {
  padding: 10px 0px;
}
.tags-date-area.settings-bz-c {
  background-color: rgba(100, 100, 111, 0.1);
  margin: 40px 0px;
  padding: 20px;
  border-radius: 5px;
}
.tags-date-area.settings-bz-c td {
  color: white;
  padding: 10px;
}
#lc-checkbox {
  display: none;
}

.lc-switch {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: rgb(99, 99, 99);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(126, 126, 126);
  box-shadow: 0px 0px 3px rgb(2, 2, 2) inset;
}
.lc-switch svg {
  width: 1em;
}
.lc-switch svg path {
  fill: rgb(48, 48, 48);
}
#lc-checkbox:checked + .lc-switch {
  box-shadow: 0px 0px 1px var(--blue2) inset, 0px 0px 2px var(--blue2) inset,
    0px 0px 10px var(--blue2) inset, 0px 0px 40px var(--blue2),
    0px 0px 100px var(--blue2), 0px 0px 5px var(--blue2);
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(146, 180, 184);
}
#lc-checkbox:checked + .lc-switch svg {
  filter: drop-shadow(0px 0px 5px var(--blue2));
}
#lc-checkbox:checked + .lc-switch svg path {
  fill: rgb(255, 255, 255);
}
.so-login-links b {
  margin: 0px 10px;
  color: #d7d7d7;
}
.so-login-links b:hover {
  color: white;
  cursor: pointer;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c {
  background-color: #008300;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c2 {
  background-color: var(--blue2);
  color: white;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c2 svg {
  margin-right: 10px;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c .arrow-right-c {
  margin-right: 0px;
  margin-left: 10px;
}
.table-space-s tr td {
  padding: 15px 20px;
}
.table-space-s tr th {
  padding: 5px 20px;
}
.icons-td-c svg {
  font-size: 18px;
}
/* .table-space-s .table-row-cc:hover{
  background-color: rgba(255, 255, 255, 0.1);
} */
.table-space-s th {
  background: radial-gradient(
    circle at 30% 107%,
    #bc182378 0%,
    #95131c68 5%,
    #700f1570 60%,
    #34070a67 90%
  );
}
span.transparent-meta-title {
  background-color: rgba(0, 0, 0, 1) !important;
  color: white;
}
.compute-img-bxs {
  width: 50px;
  border-radius: 5px;
  height: auto;
}
.alert-input-bx-v {
  display: flex;
  padding: 0px 10px;
}
.alert-input-bx-v .ant-input {
  max-width: 500px;
}
.api-key-input-c b {
  color: white;
}
.bauble_box .bauble_label {
  background: #2c2;
  background-position: 62px 5px;
  background-repeat: no-repeat;
  background-size: auto 5px;
  border: 0;
  border-radius: 50px;
  box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.4),
    0 -1px 0px rgba(0, 0, 0, 0.2), inset 0 -1px 0px #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  height: 40px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 90px;
}

.bauble_box .bauble_label:before {
  background-color: rgba(255, 255, 255, 0.2);
  background-position: 0 0;
  background-repeat: repeat;
  background-size: 30% auto;
  border-radius: 50%;
  box-shadow: inset 0 -5px 25px #050, 0 10px 20px rgba(0, 0, 0, 0.4);
  content: "";
  display: block;
  height: 30px;
  left: 5px;
  position: absolute;
  top: 6px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 30px;
  z-index: 2;
}

.bauble_box input.bauble_input {
  opacity: 0;
  z-index: 0;
}

/* Checked */
.bauble_box input.bauble_input:checked + .bauble_label {
  background-color: #c22;
}

.bauble_box input.bauble_input:checked + .bauble_label:before {
  background-position: 150% 0;
  box-shadow: inset 0 -5px 25px #500, 0 10px 20px rgba(0, 0, 0, 0.4);
  left: calc(100% - 35px);
}
.black-collapse {
  width: 100%;
}
.black-collapse .ant-collapse-header {
  color: white !important;
  background-color: var(--bgBlack2);
}
.black-collapse.ant-collapse > .ant-collapse-item {
  border-radius: 5px;
  margin-bottom: 5px;
  border-bottom-color: transparent;
}
.compute-img-tbl {
  width: 40px;
}
.learn-more-btn-exp {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.active-conn-c {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  color: white;
  border-bottom: 1px solid rgb(60, 60, 60);
  margin-bottom: 20px;
}
.active-conn-c img {
  margin: 0px 5px;
  border-radius: 5px;
}
.models-connected-c {
  display: flex;
  align-items: center;
}
.video-thumbnail-imgg .model-urls-img-c {
  width: 20px !important;
  height: 20px !important;
  max-height: 20px !important;
  min-height: 20px !important;
  margin-right: 5px;
  border-radius: 5px;
}
.dropdown-img {
  height: 18px;
  width: 18px;
  object-fit: contain;
  padding: 1px;
  background-color: black;
  margin-right: 5px;
  border-radius: 2px;
}
.image-table-loader {
  position: absolute;
  z-index: 15;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: -200px;
  background-color: black;
  border-radius: 5px;
  width: 120px;
}
.c-type-icn svg {
  font-size: 20px;
  cursor: help;
}
.csv-adjusted-table th {
  text-wrap: nowrap;
}
.csv-adjusted-table {
  width: 100%;
  overflow: auto;
  height: 80vh;
}
.active-conn-c.behaveior-c {
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-start;
}
.behavior-table-add {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.behavior-table-add td {
  padding: 10px;
}
.ant-switch .ant-switch-inner {
  background-color: rgb(42, 42, 42);
}
.ant-switch.ant-switch-checked .ant-switch-inner {
  background-color: var(--blue2);
}
.behavior-table-add h6 {
  margin-bottom: 0px;
}
.behaviour-cards-bx {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.behaviour-cards-bx .behaviour-cards {
  display: flex;
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
  background: linear-gradient(to bottom, #2a2a2a, #131313);

  min-width: 90px;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.behaviour-cards b {
  font-size: 12px;
}
.behaviour-cards:hover .show-hover {
  opacity: 1;
  display: inline;
  position: absolute;
}
.behaviour-cards:hover .opacity-h {
  opacity: 0;
}
.behaviour-cards.selected-bx-c {
  background-color: rgb(40, 59, 103);
  background: linear-gradient(to bottom, rgb(65 96 169), rgb(18, 27, 48));
  color: white;
}
.show-hover.configure-btn {
  position: absolute;
  bottom: -20px;
}
.gear-icon-c {
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 10;
}
.gear-icon-c:hover {
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.video-loading-guide {
  width: 200px;
}
.learn-more-btn-exp.between-flx {
  justify-content: space-between;
}
/* inspired form gumroad website */
.button-787 {
  --bg: #bc1823;
  --hover-text: white;
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.3em 1em;
  background: var(--bg);
  transition: 0.2s;
  margin-left: 10px;
}

.button-787:hover {
  transform: translate(-0.25rem, -0.25rem);
  background: #ccfffa;
  color: white;
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button-787:active {
  transform: translate(0);
  box-shadow: none;
}
.video-generate-load.video-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.video-generate-load.video-container > div {
  height: 100% !important;
}
.video-generate-load.video-container > div video {
  height: 100%;
  background-color: #07174e;
  object-fit: cover;
}
.models-tabs-box-mnb {
  display: flex;
  justify-content: center;
  width: 100%;
}

.models-tabs-box-mnb button {
  margin: 10px;
}
.ant-drawer-content-wrapper {
  margin: 15px;
}
.ant-drawer-content {
  border-radius: 10px;
}
.black-drawer.ant-drawer-content {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(119, 119, 119);
  color: white;
}
.black-drawer.ant-drawer-content .ant-drawer-close {
  color: white;
}
.huminoid-img-t {
  width: 100px;
}
.icon-add-plus-c {
  margin-right: 0px !important;
  font-size: 26px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.icon-add-plus-c:hover {
  background-color: var(--blue2);
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;
  color: black;
}
.hover-theme-click:hover {
  background-color: black;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  transform: scale(1.01);
}
.icon-add-plus-c svg {
  margin-right: 0px;
}
.left-area-active-conn {
  display: flex;
  align-items: center;
}
.behaviour-cards-bx.behavrious-tabs-c {
  border-right: 1px solid rgb(60 60 60);
  border-left: 1px solid rgb(60 60 60);
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
}
.flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
}
.help-text-con {
  display: flex;
}
.help-text-con h5 {
  margin-left: 20px;
}
.card-grey-bgc {
  background: linear-gradient(to bottom, #252525, #080808);
  border-radius: 5px;
}
.red-gradient-card .card-grey-bgc {
  background: linear-gradient(to bottom, #dd0108, #110000);
  box-shadow: 0px 0px 1px #dd0108;
}
.green-gradient-card .card-grey-bgc {
  background: linear-gradient(to bottom, #01a30b, #011100);
  box-shadow: 0px 0px 1px #01a30b;
}
.preview-input-c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.preview-input-c .ant-input {
  margin-bottom: 10px;
}
.preview-img-c-m {
  width: 200px;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
}
.heading-h-table {
  display: flex;
  align-items: flex-start;
}
.behav-main-cardc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.behaviour-cards-img {
  width: 100%;
  max-width: 100px;
  margin: 0px 0px;
  border-radius: 5px;
}
.behav-main-cardc {
  padding: 0px 10px;
}

.behaviour-cards-bx .behav-main-cardc .behaviour-cards {
  margin: 10px 0px;
}
.behaviour-cards.img-cards-beh {
  padding: 5px;
}
.behaviour-cards.img-cards-beh b {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #00000073;
  padding: 5px;
  border-radius: 5px;
}
.generate-behav {
  margin-right: 20px;
}
.selected-img-gen-c {
  height: 32px;
  border-radius: 5px;
}
.name-con-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 5px;
}
.name-con-img img {
  width: 70px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.name-con-img b {
  font-size: 12px;
}

.skills-hover-b {
  margin-left: 10px;
  cursor: help;
}

.list-con-card.ant-card {
  background: transparent;
  width: 100%;
  color: white;
  border-color: #2a2a2a;
  border-radius: 5px;
}
.list-con-card.ant-card * {
  border-color: #2a2a2a;
}
.ant-card .ant-card-actions {
  background-color: transparent;
}
.list-con-card.ant-card .ant-card-cover img {
  height: 150px;
  object-fit: contain;
  padding: 15px 0px;
  background-color: #2a2a2a;
}
.list-con-card.ant-card .ant-card-meta-title,
.list-con-card.ant-card .ant-card-meta-description,
.list-con-card.ant-card .anticon {
  color: white;
}
.list-con-card.ant-card .ant-card-actions > li > span > .anticon {
  color: white;
}
.list-con-card.ant-card .ant-card-actions > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-con-card.ant-card .ant-card-actions > li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-con-card.ant-card .ant-card-meta-title {
  font-size: 14px;
}
.toggle-button-cover {
  display: table-cell;
  position: relative;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button-r {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  overflow: hidden;
  background-color: #2a2a2a;
}

.checkbox-r {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer-r {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

.button-r,
.button-r .layer-r {
  border-radius: 100px;
}

#button-3 .knobs:before {
  content: "OFF";

  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 6px;
  background-color: red;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-3 .checkbox-r:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

#button-3 .checkbox-r:checked:active + .knobs:before {
  margin-left: -26px;
}

#button-3 .checkbox-r:checked + .knobs:before {
  content: "ON";

  left: 42px;
  background-color: green;
}

#button-3 .checkbox-r:checked ~ .layer-r {
  background-color: #fcebeb;
}

.button-bhv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  gap: 2px;
  height: 36px;

  border: none;
  /* background: #2a2a2a; */
  border-radius: 20px;
  cursor: pointer;
  background-color: black;
  color: white;
}

.lable-bhv {
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: 10px;
}

.button-bhv:hover .svg-icon-bhv {
  animation: spin-bhv 2s linear infinite;
}

@keyframes spin-bhv {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.black-drawer .card21 {
  margin-left: 5%;
  width: 90%;
}

.card-m-c-b {
  --font-color: #fefefe;
  --font-color-sub: #7e7e7e;
  --bg-color: #111;
  --main-color: #fefefe;
  --main-focus: #2d8cf0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  border: 2px solid #2a2a2a;
  box-shadow: 4px 4px 4px #2a2a2a;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.card-m-c-b:last-child {
  justify-content: flex-end;
}

.card-img-m-c-b {
  /* clear and add new css */
  transition: all 0.5s;
  display: flex;
  justify-content: center;
}

.card-img-m-c-b .img-m-c-b {
  /* delete */
  transform: scale(1);
  position: relative;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-top-left-radius: 80px 50px;
  border-top-right-radius: 80px 50px;
  border: 2px solid black;
  background-color: #228b22;
  background-image: linear-gradient(
    to top,
    transparent 10px,
    rgba(0, 0, 0, 0.3) 10px,
    rgba(0, 0, 0, 0.3) 13px,
    transparent 13px
  );
}

.card-img-m-c-b .img-m-c-b::before {
  /* delete */
  content: "";
  position: absolute;
  width: 65px;
  height: 110px;
  margin-left: -32.5px;
  left: 50%;
  bottom: -4px;
  background-repeat: no-repeat;
  background-image: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.7) 30%,
      transparent 30%
    ),
    linear-gradient(
      to top,
      transparent 17px,
      rgba(0, 0, 0, 0.3) 17px,
      rgba(0, 0, 0, 0.3) 20px,
      transparent 20px
    ),
    linear-gradient(to right, black 2px, transparent 2px),
    linear-gradient(to left, black 2px, transparent 2px),
    linear-gradient(to top, black 2px, #228b22 2px);
  background-size: 60% 10%, 100% 100%, 100% 65%, 100% 65%, 100% 50%;
  background-position: center 3px, center bottom, center bottom, center bottom,
    center bottom;
  border-radius: 0 0 4px 4px;
  z-index: 2;
}

.card-img-m-c-b .img-m-c-b::after {
  /* delete */
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  margin-left: -14px;
  left: 50%;
  top: -13px;
  background-repeat: no-repeat;
  background-image: linear-gradient(80deg, #ffc0cb 45%, transparent 45%),
    linear-gradient(-175deg, #ffc0cb 45%, transparent 45%),
    linear-gradient(80deg, rgba(0, 0, 0, 0.2) 51%, rgba(0, 0, 0, 0) 51%),
    linear-gradient(-175deg, rgba(0, 0, 0, 0.2) 51%, rgba(0, 0, 0, 0) 51%),
    radial-gradient(
      circle at center,
      #ffa6b6 45%,
      rgba(0, 0, 0, 0.2) 45%,
      rgba(0, 0, 0, 0.2) 52%,
      rgba(0, 0, 0, 0) 52%
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 48%,
      rgba(0, 0, 0, 0.2) 48%,
      rgba(0, 0, 0, 0.2) 52%,
      rgba(0, 0, 0, 0) 52%
    ),
    linear-gradient(
      65deg,
      rgba(0, 0, 0, 0) 48%,
      rgba(0, 0, 0, 0.2) 48%,
      rgba(0, 0, 0, 0.2) 52%,
      rgba(0, 0, 0, 0) 52%
    ),
    linear-gradient(
      22deg,
      rgba(0, 0, 0, 0) 48%,
      rgba(0, 0, 0, 0.2) 48%,
      rgba(0, 0, 0, 0.2) 54%,
      rgba(0, 0, 0, 0) 54%
    );
  background-size: 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%,
    100% 75%, 100% 95%, 100% 60%;
  background-position: center center;
  border-top-left-radius: 120px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 70px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(45deg);
  z-index: 1;
}

.card-title-m-c-b {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: var(--font-color);
}

.card-subtitle-m-c-b {
  font-size: 14px;
  font-weight: 400;
  color: var(--font-color-sub);
}

.card-divider-m-c-b {
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: 50px;
}

.card-footer-m-c-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-price-m-c-b {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color);
}

.card-price-m-c-b span {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color-sub);
}

.card-btn-m-c-b {
  height: 35px;
  background: var(--bg-color);
  border: 2px solid var(--main-color);
  border-radius: 5px;
  padding: 0 15px;
  transition: all 0.3s;
}

.card-btn-m-c-b svg {
  width: 100%;
  height: 100%;
  fill: var(--main-color);
  transition: all 0.3s;
}

.card-img-m-c-b:hover {
  transform: translateY(-3px);
}

.card-btn-m-c-b:hover {
  border: 2px solid var(--main-focus);
}

.card-btn-m-c-b:hover svg {
  fill: var(--main-focus);
}

.card-btn-m-c-b:active {
  transform: translateY(3px);
}
.card-m-c-b > img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.editor-tag-tab.active-tg-wdt.yellow-btn-c {
  background: linear-gradient(
    to bottom,
    rgba(254, 250, 0, 0.866),
    rgba(164, 176, 0, 0.828)
  );
}

.btn-yel-c {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all 0.2s;
  padding: 5px 10px;
  border-radius: 100px;
  background: #cfef00;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.btn-yel-c:hover {
  background: #c4e201;
}

.btn-yel-c > svg {
  /* width: 34px; */
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.btn-yel-c:hover svg {
  transform: translateX(5px);
}

.btn-yel-c:active {
  transform: scale(0.95);
}
.no-padding-content .ant-collapse-content-box {
  padding: 0px !important;
}
.editor-tag-tab.active-tg-wdt.yellow-button-c {
  background: linear-gradient(to bottom, lime, rgb(77, 254, 77));
  color: black;
}
.h-create-s-c {
  display: flex;
  align-items: center;
}
a > .btn-yel-c {
  background: linear-gradient(to bottom, #ccfffa, #00b1a0);
  border-radius: 5px;
  color: black;
}
a > .btn-yel-c:hover {
  background: linear-gradient(to bottom, #003f00, #001700);
}
.chat-bx-input-c {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
}

.chat-bx-input-c .icon-left-ed {
  margin: 0px;
  border-radius: 5px;
  margin-left: 3px;
  padding: 4px;
}
