@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
:root {
  --blue1: #e3f2fd;
  --bgBlack2: #ffffff14;
  --blue2: rgb(40, 59, 103);
}

body {
  background-color: black;
  font-family: "Inter";
}
.icons-top-story {
  display: flex;
}
.icons-top-story div {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
  margin: 0px 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons-top-story div:hover {
  background-color: white;
  color: var(--blue2);
  transition: 0.5s;
  cursor: pointer;
}
.create-display-icon {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
  margin: 0px 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-display-icon:hover {
  background-color: white;
  color: var(--blue2);
  transition: 0.5s;
  cursor: pointer;
}
.create-display-icon:hover svg {
  transform: scale(1.1);
}

.create-display-icon svg {
  font-size: 20px;
}

.icons-top-story div:hover svg {
  transform: scale(1.1);
}

.icons-top-story svg {
  font-size: 20px;
}

.collapse.navbar-collapse {
  margin: 0 auto;
}
.header-container .d-flex button {
  margin: 0px 10px;
}
.header-container {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.navbar-nav.me-auto.my-2 {
  margin: 0 auto;
}
* {
  padding: 0;
  margin: 0;
}

.checkmarkPrice {
  display: flex;
  align-items: center;
  margin-right: 8px; /* Adjust the margin for spacing */
  font-size: 12px; /* Adjust the font size for the checkmark */
}

.checkmarkPrice::before {
  content: "✓";
  color: green; /* Change this color to the desired color for the checkmark */
  font-size: 20px;
  margin-right: 8px;
}

.checkmark {
  color: white; /* Change this color to the desired color for the text */
}

.PriceX {
  display: flex;
  align-items: center;
  margin-right: 8px; /* Adjust the margin for spacing */
  font-size: 12px; /* Adjust the font size for the X */
}

.PriceX::before {
  content: "✕"; /* Use "✕" for X */
  color: red; /* Change this color to the desired color for the X */
  font-size: 20px; /* Adjust the font size for the X */
  margin-right: 8px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.video-container > div {
  width: 100% !important;
  margin: 0 auto;
  max-width: 700px;
  height: auto !important;
  /* max-height: 300px ; */
  border-radius: 10px;
}
.video-container > video {
  border-radius: 10px !important;
}

.rounded-video-container {
  overflow: hidden; /* This ensures the video is contained within the rounded borders */
  max-width: 90%; /* Set maximum width to 100% to fit the screen width */
}

.profile-name {
  text-align: center;
  /* margin-bottom: 40px; */
}
.profile-page .video-container {
  margin-bottom: 20px;
}
.gen-video-c .ant-typography {
  margin-bottom: 0px !important;
}
.geneated-main-create .close-as-bn {
  display: flex;
  justify-content: flex-end;
}
.geneated-main-create .close-as-bn svg:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: 0.4s;
}
.checkbox-n {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 13px;
  color: black;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.checkbox__custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(145deg, #e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.checkbox__input:checked + .checkbox__label .checkbox__custom {
  background-image: linear-gradient(145deg, #e81cff, #40c9ff);
  transform: rotate(45deg) scale(0.8);
}

.checkbox__label:hover .checkbox__custom {
  transform: scale(1.2);
}
.checbox-area-bcc {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.login-page {
  display: flex;
  align-items: center;
  height: 100vh;
}
.err-txt-signup {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
  color: brown;
}
.login-forget-btn {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.error-field {
  background-color: rgb(255, 207, 207);
}

.tabs-main-bx {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  min-height: 80vh;
  background-color: var(--bgBlack2);
}
.tabs-main-bx.tabs-scrol-y {
  max-height: 80vh;
  overflow-y: auto;
}
.card-imagine-create {
  overflow-y: auto;
}
.card-imagine-create .create-tab {
  background-color: transparent;
  margin-top: 0px;
}
.card-imagine-create2.zero-height-t .create-tab {
  height: auto !important;
}
.drop-hor-cb {
  width: 700px;
  min-height: 100px;
  overflow-x: auto;
}
.question-circle-c {
  color: black !important;
  stroke-width: 1px !important;
  /* border: 1px solid black; */
  border-radius: 50%;
  padding: 1px 4px;
  font-size: 15px !important;
  cursor: help;
}

.pagination-white .ant-pagination,
.pagination-white .ant-pagination svg,
.pagination-white .ant-pagination .ant-pagination-item-ellipsis {
  color: white !important;
}
.pagination-white .ant-pagination-item-active a {
  color: black !important;
}
.table-2-p {
  margin: 20px 0px;
}
.settings-p-bn {
  margin-top: 20px;
  color: white;
}
.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding: 0.5rem;
  border-radius: 99px;
  width: auto;
}
.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 100px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}
.glider {
  position: absolute;
  display: flex;
  height: 32px;
  width: 100px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  border-radius: 50%;
  background-color: #e6eef9;
  transition: 0.15s ease-in;
}

input[type="radio"]:checked + label {
  color: #185ee0;
}
input[type="radio"]:checked + label > .notification {
  background-color: #185ee0;
  color: #fff;
}

input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}

input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

input[id="radio-3"]:checked ~ .glider {
  transform: translateX(200%);
}

input[id="radio-4"]:checked ~ .glider {
  transform: translateX(300%);
}
.imagive-modal-cadrd {
  display: flex;
  justify-content: center;
}
.imagive-modal-cadrd .card21 {
  max-width: 550px;
  width: 100%;
  margin-left: 0px;
}
.magic-icon-h {
  margin-right: 10px;
  cursor: pointer;
}
.magic-icon-h:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}
.profile-edit-page .form-control {
  margin-top: 20px;
  color: black;
}
.balance-bx {
  color: white !important;
  margin-top: 20px;
}
.profile-edit-page {
  width: 300px;
  padding-top: 20px;
}
.create-tab {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  height: 100%;
  max-height: 550px;
}
.create-tab .ant-select-selector {
  border-radius: 20px !important;
}
.create-tab textarea {
  border-radius: 20px !important;
}

.duration-box-m {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.duration-box-m .ant-input-number {
  width: 200px;
  width: 100%;
}
.edit-video-btn {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.quick-generates-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.quick-generates-div h6 {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.quick-generates-div svg {
  font-size: 25px;
  /* animation: glowingThunder 5s linear infinite; */
}
@keyframes glowingThunder {
  0% {
    color: yellow;
  }
  20% {
    color: #002bff;
  }
  40% {
    color: yellow;
  }
  60% {
    color: #002bff;
  }

  80% {
    color: yellow;
  }
}
.glow-on-hover {
  padding: 5px 15px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 20px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 20px;
}

.glow-on-hover:hover {
  color: white;
}
.glow-on-hover:focus {
  color: white;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 20px;
  background-color: #185ee0;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.create-button-con {
  display: flex;
  justify-content: flex-end;
}
.create-tab-main {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.create-tab-main-row {
  width: 100%;
}
.create-tab-main-row .video-container {
  margin: 50px 0px 0px 30px;
}
.create-tab-main-row .video-container > div > video {
  border-radius: 15px !important;
}
.info-alert-bx {
  border-color: rgba(0, 0, 0, 0.2);
  margin-top: 40px;
}
.ring {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ring::before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
.ring h1 {
  font-size: 9px;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 0px 5px 5px #ff2121;
  }
  25% {
    transform: rotate(90deg);
    box-shadow: 0px 5px 5px #fffb21;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 0px 5px 5px #21c0ff;
  }
  75% {
    transform: rotate(270deg);
    box-shadow: 0px 5px 5px #bc21ff;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0px 5px 5px #ff2121;
  }
}
.spinner {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;

}

.spinner::before,
.spinner:after {
  content: "";
  position: absolute;
  border-radius: inherit;
  background-color: black !important;
}

.spinner:before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #ff00cc 0%, #333399 100%);
  animation: spin8932 0.5s infinite linear;

}

.spinner:after {
  width: 85%;
  height: 85%;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin8932 {
  to {
    transform: rotate(360deg);
  }
}
.spiner-main-box {
  display: flex;
}
.spiner-main-box svg {
  margin-top: 9px;
  margin-left: -29px;
  z-index: 15;
  margin-right: 20px;
}
.project-name {
  background-color: white;
  margin-bottom: 0px;
  padding: 5px 10px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-name svg {
  margin-right: 5px;
}
.project-input-con {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}
.project-input-con:hover .project-name svg {
  margin-right: 0px;
  transition: 0.5s;
  transform: scale(1.1);
}
.story-box-main-c {
  width: 100%;
  max-width: 600px;
}
.video-card-story {
  background-color: black;
  color: white;
  border: 1px solid rgb(60, 60, 60);
  padding: 10px;
  border-radius: 5px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.video-card-story.active-vd-story {
  background-color: rgb(56, 56, 56);
  transition: all ease 0.4s;
}
.card-imagine-create2 .video-card-story {
  border-width: 0px;
}
.duration-box-vid {
  color: white;
  height: 0px;
  display: flex;
  justify-content: flex-end;
}
.duration-box-vid span {
  position: relative;
  margin-top: -30px;
  margin-right: 10px;
}
.card-imagine-create2 {
  background-color: transparent;
  display: flex;
  align-items: center;
}
.card-imagine-create2 .create-tab {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0px;
}
.card-imagine-create2 .duration-box-m {
  margin-top: 0px;
}
.story-r-button {
  --hover-shadows: 8px 8px 16px black, -6px -6px 16px black;
  --accent: red;

  letter-spacing: 0.1em;
  border: 1px solid red;
  border-radius: 1.1em;
  background-color: #212121;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.1s,
    letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
}

.story-r-button:hover {
  box-shadow: var(--hover-shadows);
}

.story-r-button:active {
  box-shadow: var(--hover-shadows), var(--accent) 0px 0px 30px 5px;
  background-color: var(--accent);
  transform: scale(0.95);
}

.story-a-button {
  --hover-shadows: 8px 8px 16px black, -6px -6px 16px black;
  --accent: green;

  letter-spacing: 0.1em;
  border: 1px solid green;
  border-radius: 1.1em;
  background-color: #212121;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.1s,
    letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
}

.story-a-button:hover {
  box-shadow: var(--hover-shadows);
}

.story-a-button:active {
  box-shadow: var(--hover-shadows), var(--accent) 0px 0px 30px 5px;
  background-color: var(--accent);
  transform: scale(0.95);
}

.ap-rej-btns {
  display: flex;
  justify-content: space-between;
}
.generated-Videos-left {
  color: white;
  margin-bottom: 10px;
}
h6.generated-Videos-left {
  width: 100%;
}
.video-container-round > div > video {
  border-radius: 5px;
  margin-bottom: -5px;
}
.card-box-col-ctory {
  padding: 5px;
}
.sceen-position {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.upload-iocn-story {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-iocn-story:hover {
  cursor: pointer;
}
.upload-iocn-story:hover svg {
  transform: scale(1.02);
  transition: 0.4s;
}
.upload-iocn-story svg {
  font-size: 100px;
  color: grey;
}
.upload-icon-story {
  display: flex;
  justify-content: flex-end;
}
.upload-icon-story b {
  background-color: white;

  border-radius: 20px;
  width: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
}
.create-pro-for {
  margin-top: 20px;
  margin-bottom: 20px;
}
.create-btn-pro {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
.save-btnx {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.story-project-main {
  display: flex;
  margin-top: 15px;
}

.generated-row-story {
  width: 0px !important;
  min-width: 0px !important;

  border-radius: 10px;
  margin-top: 0px;
  min-height: 80vh;
  transition: all ease 0.5s;
  background-color: var(--bgBlack2);
  overflow-x: clip;
}
.scene-name-in .ant-input {
  margin-top: 10px;
  background-color: var(--bgBlack2);
  color: white;
  border-color: transparent;
}
.scene-name-in .ant-input::placeholder {
  color: rgb(139, 139, 139);
}
.card-box-col-ctory .ant-input-number {
  background-color: black;
  border-color: transparent;
}
.card-box-col-ctory .ant-input-number:hover {
  border-color: rgb(181, 181, 181);
}
.card-bx-hor {
  background-color: var(--bgBlack2);
  display: flex;
  width: 100%;
  margin-left: 10px;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.card-bx-ver {
  background-color: var(--bgBlack2);
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-y: auto;
  border-radius: 10px;
  padding: 10px;
  min-width: 300px !important;
}
.drop-hor-cb {
  display: flex;
}

.card-box-col-ctory .ant-input-number-input {
  color: white !important;
  border-color: transparent;
}
.ant-input-number-input::placeholder {
  color: rgb(137, 137, 137) !important;
}
.generated-row-story.show-left-s {
  min-width: 300px !important;
  padding: 20px;
  margin-right: 10px;
}
.generated-Videos-left {
  display: flex;
  justify-content: space-between;
}
.black-input-con .ant-select-selector,
.black-input-con .ant-select-selection-search {
  background-color: black !important;
  color: white;
}
.generated-Videos-left svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.video-icon-d {
  color: white;
  margin-right: 20px;
  margin-top: -10px;
  cursor: pointer;
}
.video-icon-d:hover {
  color: #7a00ff;
  transition: 0.4s;
}
.loading-full-page2 .spiner-main-box svg {
  margin-top: 12px;
  margin-left: -28px;
  z-index: 15;
  margin-right: 20px;
}
.loading-full-page .spiner-main-box svg {
  margin-top: 12px;
  margin-left: -28px;
  z-index: 15;
  margin-right: 20px;
}
.table-jobs {
  width: 800px;
  margin: 20px;
  background-color: white;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.ant-btn-primary.ant-btn:hover {
  background-color: var(--blue1);
}
.black-button,
.ant-btn-primary {
  background-color: var(--blue1);
  border-color: var(--blue1);
  color: black;
  border-radius: 20px;
}
.ant-btn-default {
  border-radius: 20px;
  background-color: #ffffff;
  border-color: white;
}
.ant-btn-default:hover {
  background-color: #ffffff !important;
  border-color: white !important;
  color: black !important;
  transition: 0.4s;

  box-shadow: rgba(0, 0, 0, 0.45) 0px 12px 10px -10px;
}
.tabs-buttons {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.tabs-buttons button {
  margin: 10px;
}
.ant-btn-primary:hover {
  background-color: var(--blue1) !important;
  border-color: var(--blue1) !important;
  transition: 0.4s;
  color: black !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 12px 10px -10px;
}
.black-button,
.ant-btn-primary:focus {
  background-color: black;
  border-color: black;
  color: white;
}
.no-buttons-modal .ant-modal-footer {
  display: none;
}
.video-container.radius-video > div > video {
  border-radius: 10px;
}
.no-buttons-modal .ant-modal-close-x .anticon svg {
  top: -12px;
  position: relative;
  right: -12px;
}
.video-main-edit-p {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.jobs-video-main {
  display: flex;
  justify-content: center;
}

.display-bx-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.display-bx-main p {
  margin-right: 20px;
}
.momen-created-at {
  display: flex;
  margin: 10px 0px;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
}
.video-main-bg-box {
  width: 100vw;
  height: 100vh;
}
.video-main-bg-box > div {
  width: 100vw !important;
  height: 100vh !important;
  object-fit: cover;
  padding-top: 56px;
}
.video-main-bg-box > div > video {
  object-fit: cover;
}
.home-page-relitive {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
.box-home-m1 {
  margin-top: 250px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
}
.img-bx-imgsc {
  padding: 10px;
}
.img-bx-imgsc img {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.content-tab {
  width: 100%;
  max-width: 800px;
}
.vd-nm-pr > div {
  width: 100% !important;
  height: auto !important;
  max-height: 300px;
}
.react-player__download {
  display: none; /* This will hide the download button */
}
.vd-nm-pr > div > video {
  border-radius: 10px !important;
  height: auto !important;
}
.icons-bx-ri-vd {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn:hover {
  position: relative;
  transform: scale(1.02);
  transition: 0.5s;
}
.icons-bx-ri-vd button {
  width: 30px;
}
.icons-bx-ri-vd button svg {
  margin-left: -5px;
}
.feed-view-area {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
}
.feed-profile-area > img {
  height: 20px;

  margin-right: 10px;
}
.feed-profile-area > img {
  font-size: 14px;
}
.feed-profile-area {
  display: flex;
  margin-bottom: 10px;
}

.feed-profile-area p {
  margin-bottom: 0px;
}
.feed-view-area .video-container > div > video {
  border-radius: 10px !important;
}
.feed-video-mc1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.likes-area {
  display: flex;
  padding: 5px 10px;
}
.likes-area > span {
  margin-left: 15px;
}
.likes-area .heart-icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.likes-area > span b {
  font-size: 12px;
  margin-left: 5px;
}
.feed-video-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  margin-top: -10px;
  background-color: #002bff;
  z-index: 12;
}
.feed-video-footer p {
  margin-bottom: 0px;
}
.likes-area svg:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.5s;
}
.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 100;
}
.feed-view-area .video-feed-mbl-w {
  display: flex;
  flex-direction: column;
  background-color: var(--bgBlack2);
  justify-content: space-between;
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}
.feed-page {
  padding-top: 80px;
}

.heart-container {
  --heart-color: rgb(255, 91, 239);
  position: relative;
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.heart-container .checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.heart-container .svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-container .svg-outline,
.heart-container .svg-filled {
  fill: var(--heart-color);
  position: absolute;
}

.heart-container .svg-filled {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.heart-container .svg-celebrate {
  position: absolute;
  animation: keyframes-svg-celebrate 0.5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--heart-color);
  fill: var(--heart-color);
  stroke-width: 2px;
}

.heart-container .checkbox:checked ~ .svg-container .svg-filled {
  display: block;
}

.heart-container .checkbox:checked ~ .svg-container .svg-celebrate {
  display: block;
}

@keyframes keyframes-svg-filled {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
    filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
    display: none;
  }
}

.InputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  border-radius: 30px;
  padding: 6px;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
}
.navbar.navbar-expand-lg.navbar-light {
  background-color: #0d0d0d;
}
.navbar.navbar-expand-lg.navbar-light {
  color: white;
}
.nav-link {
  color: white;
  font-size: 12px;
}
.nav-link.active {
  color: white !important;
  font-weight: 600;
}
.nav-link:hover {
  color: white;
}

.button-21 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px; /* Adjust the padding to reduce the size */
  border: 0;
  position: relative;
  overflow: hidden;
  border-radius: 10rem;
  transition: all 0.02s;
  font-weight: bold;
  color: black;
  z-index: 0;
  box-shadow: 0 0px 7px -5px rgba(0, 0, 0, 0.5);
}

.button-21:hover {
  background: rgb(193, 228, 248);
  color: rgb(33, 0, 85);
}

.button-21:active {
  transform: scale(0.97);
}

.hoverEffect {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.hoverEffect div {
  background: rgb(222, 0, 75);
  background: linear-gradient(
    90deg,
    rgb(220, 0, 74) 0%,
    rgb(178, 36, 253) 49%,
    #42caff 100%
  );
  border-radius: 40rem;
  width: 10rem;
  height: 10rem;
  font-weight: 600;
  transition: 0.4s;
  /* filter: blur(1px); */
  animation: effect infinite 3s linear;
  opacity: 0.5;
}
.button-21:hover .hoverEffect div {
  width: 8rem;
  height: 8rem;
}
.editor-container .button-21 {
  color: black;
}
.editor-container .hoverEffect div {
  background: #c6c6c6 !important;
  /* opacity: 1; */
  /* color: white; */
  border-radius: 0px;
  /* opacity: 1; */
  /* color: white; */
}
.card-imagine-create2 .button-21 {
  transform: scale(0.8);
  margin-top: -4px;
}

.custom-button {
  background: linear-gradient(
    to right,
    rgb(33, 0, 85) 100%,
    rgb(193, 228, 248) 0%
  );
  border: none;
  color: white;
  padding: 6px 20px; /* Adjust the padding to reduce the height */
  border-radius: 5px;
  cursor: pointer;
}

/* Add hover effect */
.custom-button:hover {
  background: linear-gradient(to right, rgb(23, 0, 75), rgb(183, 218, 238));
}

@keyframes effect {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.checkbox-wrapper * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.checkbox-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper label {
  --size: 25px;
  --shadow: calc(var(--size) * 0.07) calc(var(--size) * 0.1);
  position: relative;
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
  background-color: #4158d0;
  background: linear-gradient(to bottom, #42caff 0%, #e81aff 100%);
  border-radius: 50%;
  box-shadow: 0 var(--shadow) #ffbeb8;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
    0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
}

.checkbox-wrapper label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: calc(var(--size) * 0.7);
  height: calc(var(--size) * 0.7);
  margin: 0 auto;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset 0 var(--shadow) #ffbeb8;
  transition: 0.2s ease width, 0.2s ease height;
}

.checkbox-wrapper label:hover:before {
  width: calc(var(--size) * 0.55);
  height: calc(var(--size) * 0.55);
  box-shadow: inset 0 var(--shadow) #ff9d96;
}


.checkbox-wrapper label:active {
  transform: scale(0.9);
}

.checkbox-wrapper .tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: calc(var(--size) * -0.05);
  width: calc(var(--size) * 0.6);
  height: calc(var(--size) * 0.6);
  margin: 0 auto;
  margin-left: calc(var(--size) * 0.14);
  transform: rotateZ(-40deg);
}

.checkbox-wrapper .tick_mark:before,
.checkbox-wrapper .tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.checkbox-wrapper .tick_mark:before {
  left: 0;
  bottom: 0;
  width: calc(var(--size) * 0.1);
  height: calc(var(--size) * 0.3);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(calc(var(--size) * -0.68));
}

.checkbox-wrapper .tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--size) * 0.1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(calc(var(--size) * 0.78));
}

.checkbox-wrapper input[type="checkbox"]:checked + label {
  background-color: #4158d0;
  background: linear-gradient(to bottom, #42caff 0%, #e81aff 100%);
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 4px 3px; */
}

.checkbox-wrapper input[type="checkbox"]:checked + label:before {
  width: 0;
  height: 0;
}

.checkbox-wrapper input[type="checkbox"]:checked + label .tick_mark:before,
.checkbox-wrapper input[type="checkbox"]:checked + label .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}

.voice-main-c {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.voice-main-c i {
  margin-left: 5px;
  font-size: 13px;
}

/*Magic card21*/
.card21 {
  width: 80%;
  margin-left: 10%;
  height: auto;
  /* background: #f5f5f5; */
  overflow: visible;
  /* box-shadow: 0 5px 20px 2px rgba(0,0,0,0.1); */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
}
.editor-container .card21 {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.03)
  );
  margin-top: 20px;
}
.button-area-card {
  margin-top: -30px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.card21-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 0 1rem;
}

.card21-img {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transform: translateY(-50%);
  background: #42caff;
  background: linear-gradient(to bottom, #42caff 0%, #e81aff 100%);
  position: relative;
  transition: all 0.3s ease-in-out;
}
.editor-container .card21-img {
  background: #bc1823;
  background: linear-gradient(to bottom, #bc1823 0%, #001240 100%);
}

.card21-img::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  border: 0.5rem solid white;
}

/*text21*/
.text21-body {
  text-transform: uppercase;
  font-size: 0.75em;
  color: #42caff;
  letter-spacing: 0.05rem;
}

.text21-body {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0px;
}
.txt-card-21 {
  /* text-align: center; */
  margin-top: -20px;
  margin-bottom: 20px;
  color: rgb(85, 85, 85);
  display: flex;
  flex-direction: column;
}
.editor-container .txt-card-21,
.editor-container .text21-body,
.editor-container .text21-body,
.editor-container .checkbox__label {
  color: white;
}
.editor-container .create-tab-main-row {
  background-color: #a3a3a4;
  height: 100vh;
  padding: 20px;
}
.txt-card-21 span {
  text-align: center;
  margin-bottom: 20px;
}

.quer-genetated p {
  background-color: white;
  width: 100%;
  margin-bottom: 0px;
  padding: 10px;
  border-radius: 20px;
}
.editor-container .quer-genetated p {
  background-color: #ffffff14;
}
.editor-container .quer-genetated *,
.geneated-main-create .close-as-bn {
  color: white;
}
.geneated-main-create {
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  max-height: 550px;
}
.gen-video-c {
  overflow-y: scroll;
  height: 100%;
}
.geneated-main-create .quer-genetated {
  margin-bottom: 10px;
}
.geneated-main-create .quer-genetated p {
  display: flex;
  justify-content: space-between;
}
.geneated-main-create .quer-genetated:hover p {
  background-color: rgba(255, 255, 255, 0.45);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.geneated-main-create .quer-genetated.act-selected-v p {
  background-color: rgba(255, 255, 255, 0.2);
}
.create-tab-main-row .geneated-main-create .video-container {
  margin: 0px 0px 10px 0px;
  width: 70%;
  margin-left: 15%;
}
.video-main-edit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.video-thumbnail-img {
  width: 576px;
  height: 320px;
}
.ant-modal-content .video-thumbnail-img {
  width: 100%;
}
.video-thumbnail-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.load-more-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.play-icon-vd {
  position: absolute;
  top: 100px;
  /* left: 0;
  bottom: 0;
  right: 0; */
  z-index: 20;
  background-color: #002bff;
}

.large-play-btn {
  width: 60px !important;
  height: 60px !important;
  font-size: 25px !important;
  border-radius: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.large-play-btn svg {
  margin-left: 4px !important;
}
.icons-bx-ri-vd2 {
  display: flex;
  justify-content: center;
}
.large-play-btn {
  margin-top: -180px;
  background: rgba(240, 248, 255, 0.343);
  color: #103dce;
}
.large-play-btn:hover {
  color: #103dce;
}
.small-play-btn {
  width: 45px !important;
  height: 45px !important;
  border-radius: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-play-btn svg {
  font-size: 20px !important;
  margin-left: 4px !important;
  position: absolute;
}
.play-video-loader {
  margin-top: -90px;
  font-size: 30px;
}
.play-video-loader svg {
  color: black;
}
.small-play-btn {
  margin-top: -90px;
  background: linear-gradient(
    90deg,
    rgba(220, 0, 74, 0.5) 0%,
    rgba(178, 36, 253, 0.5) 49%,
    #43caff 100%
  );
}
.arow-div-con {
  display: flex;
  justify-content: space-between;
  height: 0px;
  opacity: 0;
}
.video-card-story:hover .arow-div-con {
  opacity: 1;
  transition: 0.4s;
}
.arow-div-con svg {
  position: relative;
  z-index: 15;
  margin: 5px;
  background-color: black;
  padding: 2px;
  border-radius: 5px;
}
.arow-div-con svg:hover {
  transform: scale(1.2);
  transition: 0.4s;
  cursor: pointer;
}
.arow-div-con svg:active {
  color: #4158d0;
  background-color: white;
}
.search-for-craate-story {
  display: flex;
  justify-content: center;
  height: 0px;
}
.searct-create-m {
  position: absolute;
  top: 15px;
}
.searct-create-m .ant-select-arrow {
  color: white !important;
}
.searct-create-m .ant-select-selection-search {
  padding-right: 30px;
}
.searct-create-m .ant-select-selector {
  padding-left: var(--size-button);
  height: var(--size-button);
  font-size: 15px;
  border: none;
  color: #fff;
  outline: none;
  width: var(--size-button);
  color: white;
  transition: all ease 0.3s;
  background-color: #191a1e !important;
  border-color: transparent !important;
  box-shadow: 0px 0px 0px #0e0e0e, 0px 0px 0px rgb(95 94 94 / 25%),
    inset 1.5px 1.5px 3px #0e0e0e, inset -1.5px -1.5px 3px #5f5e5e;
  border-radius: 50px;
  padding: 5px;
}
.searct-create-m .ant-select-selection-placeholder {
  color: rgb(119, 119, 119) !important ;
}
.searct-create-m .create-button-con {
  color: white;
  margin-left: -35px;
  position: relative;
  z-index: 20;
  margin-right: 10px;
}
.searct-create-m .create-button-con svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.searct-create-m .create-button-con svg:active {
  color: #4158d0;
}

.search-for-craate-story .create-tab {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 0px;
}
.search-for-craate-story .duration-box-m {
  margin-top: 0px;
}

.play-feed-profile .large-play-btn {
  width: 50px !important;
  height: 50px !important;

  margin-top: -90px;
}
.feed-video-footer.thumbnail-footer {
  background-color: black;
  color: white;
  border-radius: 20px;
  margin-bottom: -40px;
  z-index: 15;
  margin-top: 5px;
  width: 94%;
  margin-left: 3%;
}
.feed-video-footer.thumbnail-footer b {
  font-weight: 500;
}
.duration-box b {
  background-color: black;
  padding: 7px 10px;
  border-radius: 20px;
  margin: 0px 10px;
  margin-top: -40px;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: white;
}
.feed-video-footer .ant-typography {
  color: white;
  margin-bottom: 0px;
  margin-left: 5px;
}
.ant-upload-drag-container {
  color: white;
}
.reimagine-b-mai {
  display: flex;
  justify-content: flex-end;
}
.duration-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0px;
}
.logo-img-video-create {
  width: 10px;
  margin-right: 7px;
  margin-left: 10px;
  background-color: white;
  border-radius: 50%;
  padding: 1px;
  transform: scale(2);
}
.logo-img-bc {
 margin: 5px 10px 5px 10px !important;
}
.optional-text {
  font-size: 12px;
  margin-left: 10px;
}
.business-tools-btn {
  width: 100%;
  display: flex;
  height: 0px;
  justify-content: flex-end;
}
.business-tools-btn button {
  position: relative;
  top: -40px;
  right: -20px;
  padding-left: 10px;
  padding-right: 10px;
}
.business-tools-btn2 {
  width: 100%;
  display: flex;
  height: 0px;
}
.business-tools-btn2 button {
  position: relative;
  top: -40px;
  left: -20px;
  padding-left: 10px;
  padding-right: 10px;
}
.modal-upload-c {
  margin-top: 20px;
}
.sel-asset-c {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.card-bx-con-fl2 .img-gen {
  max-height: 150px;
  width: auto;
  object-fit: contain;
  border-radius: 5px;
}
.card-bx-con-fl {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
}
.card-bx-con-fl2 {
  margin: 5px;
  padding: 5px;

  display: flex;
  justify-content: center;
}
.card-bx-con-fl2 p {
  margin-bottom: 0px;
}
.tags-ctype-btns {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.login-box-bg {
  background-color: var(--bgBlack2);
  border-radius: 15px;
  height: 100%;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.black-forget-modal .ant-modal-body {
  background-color: var(--bgBlack2);
}
.login-btn-c21 {
  display: flex;
  justify-content: flex-end;
}
.black-modal .ant-modal-content {
  box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
  background-color: black;
  color: white;
}
.black-modal .ant-modal-close {
  color: white;
}
.login-box-bg h3,
.login-box-bg p {
  color: white;
}
.signup-txt-login {
  background: linear-gradient(#ffc7cb 0%, rgb(246, 246, 246) 100%);
  /* background: -webkit-linear-gradient(#eee, #333); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}
.signup-txt-login:hover {
  background: linear-gradient(white 0%, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  transform: scale(1.01);
}

.btn34 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  overflow: hidden;
  height: 2.5rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  margin-top: 20px;
  animation: gradient_301 5s ease infinite;
  border: double 3px transparent;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #fe53bb 45%,
      #8f51ea 67%,
      #0044ff 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#container-stars34 {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

.btn34 strong {
  z-index: 2;
  font-size: 12px;
  letter-spacing: 5px;
  color: #ffffff;
  text-shadow: 0 0 4px white;
}

#glow34 {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle34 {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle34:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle34:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn34:hover #container-stars34 {
  z-index: 1;
  background-color: #212121;
}

.btn34:hover {
  transform: scale(1.01);
}

.btn34:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn34:active .circle34 {
  background: #fe53bb;
}
.video-main-reimag {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
}
.video-main-reimag button {
  position: relative;
  top: -20px;
  right: 7px;
  z-index: 20;
}

#stars34 {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars34::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars34::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars34::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars34::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.login-butotn-d {
  display: flex;
  justify-content: flex-end;
}
.up-bx-mnbcs {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.pricing-modal .ant-modal-close-x {
  display: none;
}
.search-video-content {
  display: flex;
  justify-content: center;
}

.video-feed-mbl-w .ant-typography {
  max-width: 300px;
}
.card61 {
  width: 100%;
  height: auto;
  background-color: #4158d0;
  background-image: linear-gradient(
    45deg,
    #4158d0 0%,
    rgb(0, 17, 103) 46%,
    #4158d0 100%
  );
  border-radius: 8px;
  color: white;
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
}

.card-content61 {
  padding: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.card-content61 .card-title61 {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
}

.card-content61 .card-para61 {
  color: inherit;
  opacity: 0.8;
  font-size: 14px;
}

.card61:hover {
  transform: rotateY(2deg) rotateX(2deg) scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.play-video-likes-b {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card61:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
}

.card61:hover:before {
  transform: translateX(-100%);
}

.card61:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
}

.card61:hover:after {
  transform: translateX(100%);
}

.prifing-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-left: 10px;
}
.btnpr {
  width: 6.5em;
  height: 2.3em;
  margin: 0.5em;
  background: black;
  color: white;
  border: none;
  border-radius: 0.625em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.prbutton:hover {
  color: black;
}

.prbutton:after {
  content: "";
  background: white;
  position: absolute;
  z-index: -1;
  left: -20%;
  right: -20%;
  top: 0;
  bottom: 0;
  transform: skewX(-45deg) scale(0, 1);
  transition: all 0.5s;
}

.prbutton:hover:after {
  transform: skewX(-45deg) scale(1, 1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.price-card-box {
  padding: 10px;
}
.icons-bx-main-ne {
  background-color: var(--bgBlack2);
  padding: 20px;
  border-radius: 20px;
}
.img-prev-ibc {
  margin: 0 auto;
}
.click-profile-b:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: 0.4s;
}
.profile-feed-a {
  background-color: var(--bgBlack2);
  padding: 30px;
  border-radius: 10px;
  max-width: 900px;
  margin: 0 auto;
}
.profile-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: rgb(187, 187, 187);
  padding-bottom: 20px;
  border-bottom: 1px solid grey;
}
.profile-name img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}
.play-feed-profile img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 24px;
  border-radius: 10px;
}
.profile-name.pro-tb img {
  width: 100px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
  margin-right: 20px;
}
.white-color-para {
  color: white;
}
.icons-bx-sd {
  display: flex;
  align-items: center;
}
.icons-bx-sd button {
  margin-right: 5px;
}
.profile-edit-page {
  display: flex;
  justify-content: center;
}
.profile-edit-page table td {
  padding: 10px 20px;
}
.profile-edit-page table {
  background-color: white;
  width: 550px;
  border-radius: 15px;
}
.or-bx-vv {
  display: flex;
  justify-content: center;
  margin: 5px 0px;
}
.avatar-c-bx {
  display: flex;
  margin-bottom: 0px;
}
.avatar-c-bx img {
  border-radius: 10px;
  width: 100px;
  margin-bottom: 10px;
}
.create-avatar-bx {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.play-feed-profile img:hover {
  cursor: pointer;
  transition: 0.4s;
  transform: scale(1.02);
}

.table-box-adwance td {
  padding: 5px 10px;
}
.table-box-adwance .InputContainer {
  margin-bottom: 5px;
}
.collapse-style-adwance .ant-collapse {
  width: 320px;
}
.collapse-style-adwance .ant-collapse-header {
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  color: white;
}

.feed-vide-share-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.collapse-style-adwance {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}
.likes-fav-video {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.likes-fav-video b {
  color: white;
}
.white-color-paragraph .ant-typography {
  color: white;
}
.star-div {
  display: flex;
}
.total-video-profile {
  padding-left: 65px;
  margin-top: 10px;
}
.img-prev-ibc {
  width: 100px;
}
.pic-2vid0c {
  width: 10px;
  height: 0px;
  display: none;
}
.container212 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.InputContainer .ant-select-selector {
  border-radius: 20px;
}
.container212 {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.container212 svg {
  position: relative;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  transition: all 0.3s;
  fill: #666;
}
.color-select-boxz {
  display: flex;
  flex-wrap: wrap;
}
.color-select-boxz div {
  padding: 5px;
  margin: 5px;

  color: white;
  border-radius: 5px;
  cursor: pointer;
  min-width: 50px;
  text-align: center;
  height: 30px;
}
.color-select-boxz span:hover {
  transform: scale(1.1);
  transition: 0.4s;
}
.flex-end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.container212 svg:hover {
  transform: scale(1.1);
}

.container212 input:checked ~ svg {
  fill: #ffeb49;
}
.play-feed-profile .video-container video {
  border-radius: 5px;
}
.play-feed-profile .video-container video {
  border-radius: 5px;
}

.container-s {
  position: relative;
  --size-button: 40px;
  color: white;
  margin-left: 100px;
}

.input-s {
  padding-left: var(--size-button);
  height: var(--size-button);
  font-size: 15px;
  border: none;
  color: #fff;
  outline: none;
  width: var(--size-button);
  transition: all ease 0.3s;
  background-color: #191a1e;
  box-shadow: 1.5px 1.5px 3px #0e0e0e, -1.5px -1.5px 3px rgb(95 94 94 / 25%),
    inset 0px 0px 0px #0e0e0e, inset 0px -0px 0px #5f5e5e;
  border-radius: 50px;
  cursor: pointer;
}

.input-s:focus,
.input-s:not(:invalid) {
  width: 200px;
  cursor: text;
  box-shadow: 0px 0px 0px #0e0e0e, 0px 0px 0px rgb(95 94 94 / 25%),
    inset 1.5px 1.5px 3px #0e0e0e, inset -1.5px -1.5px 3px #5f5e5e;
}

.input-s:focus + .icon-s,
.input-s:not(:invalid) + .icon-s {
  pointer-events: all;
  cursor: pointer;
}

.container-s .icon-s {
  position: absolute;
  width: var(--size-button);
  height: var(--size-button);
  top: 0;
  left: 0;
  padding: 8px;
  pointer-events: none;
}

.container-s .icon-s svg {
  width: 100%;
  height: 100%;
}
.navbar-my-main {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.container-fluid.my-container-flui {
  display: flex;
  flex-wrap: nowrap;
}
.header-right-c {
  display: flex;
  align-items: center;
}
.style-card-mn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
}
.style-card-mn img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: white;
}
.style-card-mn p {
  margin-bottom: 0px;
}
.black-modal .ant-modal-header {
  background-color: black;
}
.black-modal .ant-modal-title {
  color: white;
}
.loading-full-page {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-full-page .spiner-main-box {
  transform: scale(3);
}
.loading-full-page.trasparent-loader {
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-full-page2 {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-full-page2 .spiner-main-box {
  transform: scale(3);
}
.cta-buttons-c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cta {
  position: relative;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  text-align: left;
  margin-top: 15px;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}
.clicked-thunder-query {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  text-align: left;
  margin-top: 15px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  border-radius: 20px;
}

.cta span {
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: black;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: black;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}
.cta-buttons-c h6 {
  margin-top: 10px;
  padding-top: 5px;
  width: 100%;
  margin-left: 10px;
}
.gradient-alert {
  background: linear-gradient(-45deg, rgb(227, 213, 255), rgb(255, 231, 231));
  background-size: 400% 400%;
  animation: gradient31 5s ease infinite;
  border-color: transparent;
}
.thunder-icon {
  font-size: 22px;
}
@keyframes gradient31 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.all-gen-video-txt {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.input-search-story.input-s {
  padding: 5px 10px;
  width: 100%;
  margin: 10px 0px;
}
.button-tab {
  padding: 0.6em 1.3em;
  font-weight: 900;
  font-size: 12px;
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 0.3),
    rgba(0, 170, 255, 0.3)
  );
  /* border-radius: 10px; */
  border: none;
  color: aliceblue;
  margin: 3px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  /* box-shadow: 0 0 10px rgba(55, 0, 255, 0.5); */
  cursor: pointer;
  border-radius: 5px;

  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.button-tab:hover {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 0.7),
    rgba(0, 170, 255, 0.7)
  );
  transition: 0.4s ease all;
}
.gb-tag-brand {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 0.3),
    rgba(0, 170, 255, 0.3)
  );
  color: white;
  font-weight: 500;
  border-color: transparent;
  cursor: pointer;
}
.gb-tag-brand.active-ctype-tag {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 1),
    rgba(0, 170, 255, 1)
  );
  color: white !important;
  border-color: transparent;
}
.black-input {
  background-color: rgb(28, 28, 28);
  color: white;
}
.black-input::placeholder {
  color: #aaaaaa;
}
.ant-tour-close {
  display: none !important;
}
.ant-tour-step-move {
  transition: transform 0.7s ease; /* Adjust the duration (0.3s) and timing function (ease) as needed */
}
.button-tab.active-btn-t {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 1),
    rgba(0, 170, 255, 1)
  );
  transform: scale(1.1);
  transition: 0.4s ease all;
}
.term-page-box-fc {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  color: black;
  max-height: 450px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.term-page-box-fc p {
  margin-left: 20px;
}
.ant-tour {
  width: 320px;
}
.img-table {
  width: 100px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
}
.table-box-character {
  margin-top: 10px;
  width: 100%;
  min-width: 700px;
}
.text-meda-desc {
  max-width: 300px;
  max-height: 100px;
  overflow-y: auto;
}
.delete-icon {
  font-size: 20px;
  cursor: pointer;
}
.delete-icon:hover {
  transform: scale(1.1);
  transition: 0.4s;
  color: red;
}
.delete-icon:active {
  color: rgb(158, 1, 1);
}
.hidden-textarea {
  border-color: transparent;
  border-radius: 5px;
}
.hidden-textarea:hover {
  border-color: #ddd;
}
.table-top-filters {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.black-table tr {
  background-color: black;
  color: white;
}
.black-table tr:hover td {
  background-color: rgb(15, 15, 15) !important;
  transition: 0s !important;
}

.black-table tr:hover {
  background-color: rgba(0, 0, 0, 0.075) !important;
}
.black-table .ant-table-thead > tr > th,
.black-table .ant-table-thead > tr > td {
  border-color: transparent;
}
.black-table .ant-table-tbody > tr > th,
.black-table .ant-table-tbody > tr > td {
  border-color: rgb(62, 62, 62) !important;
}
.black-table .ant-table-thead > tr > td,
.black-table th.ant-table-cell {
  background: linear-gradient(
    to right,
    rgba(52, 52, 52, 0.1),
    rgba(170, 170, 170, 0.1)
  ) !important ;
  color: white !important;
  border-bottom: black;
}
.ant-table-thead:hover {
  background-color: black;
}
.black-table
  :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.black-table
  :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: black;
}
.black-table .ant-typography {
  color: white;
}
.black-table .ant-table-placeholder{
  background-color: black !important;

}
.black-table .ant-table-placeholder .ant-empty-description{
  color: rgb(210, 210, 210);
}
.black-input .ant-select-selector {
  background-color: black !important;
  color: white;
}
.black-input .ant-select-arrow {
  color: white !important;
}
.plus-button-add.ant-btn-default {
  background-color: black;
  color: white;
  border-radius: 5px;
}
.plus-button-add.ant-btn-default:hover {
  background-color: black !important;
  color: white !important;
  border-color: 3f96ff !important;
}
.ant-table-container-m {
  /* background-color: #00ffd5; */
  display: flex;
  justify-content: center;

  /* overflow-x: auto; */
}
.monti-m-button {
  display: flex;
  justify-content: flex-end;
}
.monti-m-button button {
  margin-left: 10px;
}
.caharacter-m-mid {
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
}
.price-desc-d {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 15px;
  margin-bottom: 20px;
}
.black-input .ant-input-number-input {
  color: white;
}
.user-select-m {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.market-card-bx {
  padding: 10px;
  background-color: #191a1e;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.market-card-bx img {
  margin-bottom: 10px;
}
.next-btn-f {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.containermn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containermn {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.containermn svg {
  position: relative;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  transition: all 0.3s;
  fill: #666;
}

.containermn svg:hover {
  transform: scale(1.1);
}

.containermn input:checked ~ svg {
  fill: #ffeb49;
}
.stars-bx-fils {
  display: flex;
}
.input-right-radius .ant-select-selection-placeholder {
  color: #a0a0a0;
}
.input-right-radius .ant-select-selector {
  border-radius: 5px !important;

  height: 36px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: rgb(42, 42, 42) !important;
  color: white !important;
  border-color: transparent !important;
}
.select-left-radius .ant-select-selector {
  border-radius: 5px !important;
  height: 36px !important;

  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: rgb(42, 42, 42) !important;
  color: white !important;
  border-color: transparent !important;
  border-left-color: rgb(61, 61, 61) !important ;
}

.ctype-selected {
  margin-right: 10px;
  margin-top: 5px;
  text-transform: capitalize;
  color: gray;
}
.ctype-selected .close-icon-top-s {
  color: gray;
  background-color: transparent;
  padding: 0px;
  font-size: 16px;
}
.ctype-selected .close-icon-top-s:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: white;
}
.ctype-selected svg {
  background-color: var(--blue2);
  color: white;
  padding: 5px;
  font-size: 25px;
  border-radius: 50%;
}
.gb-tag-brandv {
  width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-bottom: 20px;
  max-width: 160px;
}
.gb-tag-brandv:hover {
  transition: 0.5s;
  transform: scale(1.04);
  cursor: pointer;
}

.create-tab-main-row .name-tg-b .ant-input,
.create-tab-main-row .name-tg-b input {
  color: white;
  border-color: transparent;
  border-radius: 4px !important;
}
.create-tab-main-row .name-tg-b .ant-input:focus,
.create-tab-main-row .name-tg-b input:focus {
  background-color: #373737;
  color: white;
}

.gb-tag-brandv.active-ctype-tag {
  border: 5px solid white;
}
.transition-row .ant-col {
  transition: all 0.4s ease-in-out;
}
.select-type-csc {
  color: white;
  margin-bottom: 20px;
  font-size: 20px;
}
.sel-css-btns .editor-tag-tab {
  background-color: black;
  color: white !important;
}
.sel-css-btns .editor-tag-tab:hover {
  background-color: black !important ;
  color: white !important;
}
.sel-css-btns .editor-tag-tab:active {
  background-color: rgba(255, 255, 255, 0.4) !important ;
  color: white !important;
  border-color: transparent;
}
.sel-css-btns {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: rgba(0, 170, 255, 0.2);
  border-radius: 5px;
}
.sel-css-btns .editor-tag-tab {
  margin: 5px;
}

.up-sel-b-a {
  background-color: rgba(255, 255, 255, 0.1);
  min-height: 200px;
  padding: 20px;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.up-sel-b-a .ant-checkbox-wrapper span {
  color: white;
}

.btn-uplcs {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
}

.btn-uplcs::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
}

.btn-uplcs:hover {
  color: #e8e8e8;
}

.btn-uplcs:hover::before {
  width: 100%;
}
.detail-text-sc {
  background-color: black;
  padding: 10px;
  border-radius: 5px;
}
.grey-input .ant-select-selection-placeholder {
  color: rgb(163, 163, 163);
}
.padding-table td {
  padding: 5px;
}
.button-36 {
  background-image: linear-gradient(
    92.88deg,
    #455eb5 9.16%,
    #5643cc 43.89%,
    #673fd7 64.72%
  );
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 2.2rem;
  padding: 0 10px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: 0.1s;
}
.header-c-desk {
  width: 100%;
}
.mobile-header-crt {
  display: none;
}

.mobile-header-crt .start-icons-bx.less-width-bx {
  margin-bottom: 10px;
}

.mobile-header-crt .bottom-icons-jh.start-icons-bx.less-width-bx {
  margin-top: 10px;
  margin-bottom: 0px;
}

.mobile-header-crt
  .bottom-icons-jh.start-icons-bx.less-width-bx
  .icon-left-ed.active-i-l-ed {
    background: var(--blue2);
  border-radius: 3px;
  color: black;
}
.mobile-header-crt .bottom-icons-jh.start-icons-bx.less-width-bx .icon-left-ed {
  width: 25px;
  height: 25px;
}
.mobile-header-crt
  .bottom-icons-jh.start-icons-bx.less-width-bx
  .icon-left-ed
  svg {
  font-size: 14px;
  color: white;
}
.mobile-header-crt .white-color-icon {
  color: white;
}

.landing-img-lf {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.footer-main-c {
  margin: 100px 0px 0px 0px;
  /* border-top: 1px solid rgb(35, 35, 35); */
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.05);
}
.footer-title {
  color: white;
  font-weight: 500;
}
.nav-link {
  color: rgb(205, 205, 205);
}
.nav-link:hover {
  color: white;
}
.page-bx-main-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.videos-uploading-modal {
  position: fixed;
  right: 1rem;
  z-index: 1000;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px 10px 0 0;
  width: 276px;
  bottom: 0;
  transition: 0.4s ease;
}

.videos-uploading-modal.active {
  bottom: 0;
}

.videos-uploading-modal.lowered {
  transform: translateY(calc(100% - 45px));
}

.videos-uploading-modal svg {
  transition: 0.4s ease;
}

.videos-uploading-modal.lowered svg {
  transform: rotateX(180deg);
}

.videos-uploading-modal .head {
  background-color: #111828;
  color: #fff;
  padding: 10px 14px;
  border-radius: 10px 10px 0 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.videos-uploading-modal .body {
  background-color: #fff;
}

.videos-uploading-modal .body .uploaded-item {
  padding: 6px 14px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videos-uploading-modal .body .completed {
  width: 20px;
  height: 20px;
  position: relative;
}

.videos-uploading-modal .body .completed img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.videos-uploading-modal .body .completed img.cross {
  opacity: 0;
}

.videos-uploading-modal .body .uploaded-item:hover .completed img.cross {
  opacity: 1;
  cursor: pointer;
}

.videos-uploading-modal .body .uploaded-item:hover .completed img.tick {
  opacity: 0;
}

.videos-uploading-modal .body .uploaded-item:hover .completed img.error {
  opacity: 0;
}
.uploaded-item:hover .close-bx{
  font-size: 20px;
  color: red;
  position: absolute;
  cursor: pointer;
}
.name-bx-space{
  display: flex;
  margin: 20px 0px;
  align-items: center;
}
.name-bx-space b{
  margin-right: 20px;
}
.space-bxs-s{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.space-bxs-s > div{
  margin: 10px 0px;
  padding: 5px 10px;
}
@media (max-width: 850px) {
  .header-c-desk {
    display: none;
  }
  .mobile-header-crt {
    display: block;
  }
  .header-create-c {
    flex-direction: column;
  }
  .ant-btn.ant-btn-sm {
    font-size: 12px;
  }
  .sel-css-btns {
    flex-wrap: wrap;
  }
  .black-modal .ant-input {
    width: 250px !important;
  }
}
@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}
@media screen and (min-width: 600px) {
  .hide-desktop{
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .grey-input.main-search-cine {
    max-width: 300px;
  }
  .button-tab {
    padding: 5px 5px;
    font-weight: 900;
    font-size: 10px;
  }
  .hide-mobile {
    display: none;
  }
  .feed-view-area .video-feed-mbl-w {
    margin: 10px;
    /* box-shadow: rgba(17, 12, 46, 0.07) 0px 24px 50px 0px; */
    width: 100%;
  }
  .feed-view-area {
    padding-top: 10px;
  }
  .video-thumbnail-img {
    width: 100%;
    height: 220px;
  }
  .large-play-btn {
    margin-top: -140px;
  }

  .create-tab-main {
    padding: 0px;
    padding-bottom: 10px;
  }
  .input-s {
    width: 140px !important;
    padding-left: 8px;
  }
  .container-s .icon-s {
    display: none;
  }
  .container-s {
    margin-left: 0px;
  }
  .my-container-flui .custom-button {
    padding: 5px;
  }
  .container.profile-mn.my-5 {
    margin-top: 10px !important;
  }
  .business-tools-btn {
    display: none;
  }
  .profile-edit-page table {
    width: 100%;
  }
  .tabs-main-bx {
    padding: 10px;
  }


  .table-box-character {
    min-width: 300px !important;
  }
  .black-table .ant-table-thead > tr > th,
  .black-table .ant-table-tbody > tr > th,
  .black-table .ant-table-tbody > tr > td,
  .black-table tfoot > tr > th,
  .black-table tfoot > tr > td {
    padding: 10px 5px;
  }
  .black-table .ant-spin-nested-loading {
    width: 360px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 1400px) {
  .content-ed-main .video-container > div {
    max-width: 500px;
  }
}
@media screen and (max-width: 1100px) {
  .content-ed-main .video-container > div {
    max-width: 400px;
  }
  .video-thumbnail-img {
    width: 80%;
    margin: 0 auto;
  }
}
